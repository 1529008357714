export class BankAccountModel {
  year: number;
  month: number;
  startStatus: number;
  endStatus: number;
  projectId: number;

  public constructor(init?: Partial<BankAccountModel>) {
    Object.assign(this, init);
  }
}
