import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserModel } from '@app/_common/models';
import { MyErrorStateMatcher } from '@app/_helpers';
import { UserService } from '@app/_services';
import moment, { Moment, months } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  settingsForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  currentUser: UserModel;
  photoBase64: string;
  isLoading: boolean = false;
  currentWeekString: string = 'שבוע';

  isEdit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<SettingsComponent>
  ) {}

  ngOnInit(): void {
    this.settingsForm = this.formBuilder.group({
      name: [null, Validators.required],
      startMonthDay: [
        null,
        [Validators.required, Validators.min(1), Validators.max(28)],
      ],
      photoBase64: [null],
    });

    this.loadCurrentUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCurrentUser(): void {
    this.isLoading = true;
    this.userService
      .getMe()
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.currentUser = data;
        this.photoBase64 = '/' + this.currentUser.photoUrl;

        const today: Moment = moment().startOf('day');
        let starDate: Moment = today.clone();
        if (starDate.date() < this.currentUser.startMonthDay) {
          starDate = moment()
            .subtract(1, 'month')
            .date(this.currentUser.startMonthDay);
        } else {
          starDate = moment()
            .startOf('month')
            .date(this.currentUser.startMonthDay);
        }

        let endDate: Moment = starDate.clone();
        let numberOfWeeks: number = 0;
        while (endDate <= today) {
          endDate = endDate.add(1, 'week');
          numberOfWeeks++;
        }

        if (starDate.add(1, 'month') < endDate) {
          endDate = starDate;
        }

        this.currentWeekString =
          'שבוע ' +
          numberOfWeeks +
          ' (' +
          endDate.clone().add(-1, 'week').date() +
          '-' +
          endDate.clone().add(-1, 'day').date() +
          ') ';

        this.settingsForm.patchValue(data);
      });
  }

  loadFile(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.photoBase64 = reader.result as string;
      this.settingsForm.patchValue({
        photoBase64: this.photoBase64,
      });
    };
  }

  edit(): void {
    this.isEdit = true;
  }

  submit(event: any): void {
    if (this.settingsForm.invalid) {
      return;
    }

    const user = { ...this.currentUser, ...this.settingsForm.value };
    this.isLoading = true;
    this.userService
      .update(user)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.toastr.success('עודכן');
          this.isEdit = false;
          this.loadCurrentUser();
        },
        error => {
          this.toastr.error('Error');
        }
      );
  }

  cancelHandler(event: any): void {
    this.isEdit = false;
    this.loadCurrentUser();
  }
}
