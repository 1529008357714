import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SavingModel } from '../_common/models/saving';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SavingService {
  constructor(private http: HttpClient) {}

  get(id: number) {
    return this.http.get<SavingModel>(`api/v1/saving/${id}`);
  }

  save(saving: SavingModel) {
    return this.http.post<SavingModel>(`api/v1/saving`, saving);
  }

  update(saving: SavingModel) {
    return this.http.put<SavingModel>(`api/v1/saving`, saving);
  }

  delete(id: number) {
    return this.http.delete(`api/v1/saving?id=${id}`);
  }

  list(
    savingId?: number,
    offset: number = 0,
    size?: number,
    userId?: number,
    search?: string,
    withSummary: boolean = true,
    year?: number,
    month?: number
  ): Observable<SavingModel[]> {
    let query = `offset=${offset}&withSummary=${withSummary}`;

    if (size) {
      query += `&size=${size}`;
    }

    if (userId) {
      query += `&userId=${userId}`;
    }

    if (search) {
      query += `&search=${search}`;
    }

    if (year) {
      query += `&year=${year}`;
    }

    if (month) {
      query += `&month=${month}`;
    }

    if (savingId) {
      query += `&savingId=${savingId}`;
    }

    return this.http.get<SavingModel[]>(`api/v1/saving/list?${query}`);
  }
}
