import { Injectable } from '@angular/core';

import { DataModel } from '../_common/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {
  private dataSource = new BehaviorSubject<DataModel>(new DataModel());
  data = this.dataSource.asObservable();

  constructor() { }

  updatedDataSelection(data: DataModel) {
    this.dataSource.next(data);
  }
}
