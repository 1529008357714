export class CategoryModel {
  id: number;
  createdDate: string;
  userId: number;
  name: string;
  budget: number;
  description: string;
  type: TypeOfCategoryCycle;
  isSendExpensesPushNotification: boolean;
  categoryType: CategoryType;
  budgetDate: string;
  notes: string;
  projectId: number;
  isYearlySupport: boolean;
  yearlyBudget: number;

  public constructor(init?: Partial<CategoryModel>) {
    Object.assign(this, init);
  }
}

export enum TypeOfCategoryCycle {
  Monthly = 0,
  Weekly = 1,
  Business1 = 2,
  Business2 = 3,
  OneTime = 4,
  AdHoc = 5
}

export enum CategoryType {
  Expense = 0,
  Income = 1
}
