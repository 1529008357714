import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';
import { LoanModel } from '@app/_common/models/loan';
import { SavingModel } from '@app/_common/models/saving';
import { CategoryEditComponent } from '@app/_shared/category-edit/category-edit.component';
import { CreateDefaultCategoriesComponent } from '@app/_shared/create-default-categories/create-default-categories.component';
import { SavingWidgetComponent } from '@app/savings/saving-widget/saving-widget.component';
import 'chartjs-plugin-style';
import * as _ from 'lodash';
import * as _moment from 'moment';
import { Moment, default as _rollupMoment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  CategoryModel,
  CategoryType,
  ProjectModel,
  ProjectType,
} from '../_common/models';
import {
  AuthenticationService,
  CategoryService,
  DataService,
  EventService,
  EventType,
  HelperService,
  LoanService,
  ObjectType,
  ProjectService,
  ReportService,
  SavingService,
} from '../_services';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  public incomesTable = { rows: [] };
  public incomesTotal = { planning: 0, incomesSum: 0 };
  public expensesTable = { rows: [] };
  public expensesTotal = { planning: 0, expensesSum: 0 };
  public totalTable = { rows: [] };

  public mainTotalTable = { rows: [] };

  public startMonthDate: string;
  public endMonthDate: string;
  public date: UntypedFormControl;

  public projectControl = new UntypedFormControl();
  projects: ProjectModel[] = [];
  currentProject: ProjectModel;

  private userId = null; //current user

  public isLoading: boolean = false;
  isFirstLoad: boolean = true;

  private subs: Subscription = new Subscription();

  constructor(
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    private projectService: ProjectService,
    private eventService: EventService,
    private savingService: SavingService,
    private loanService: LoanService,
    private helperService: HelperService,
    private dataService: DataService,
    private categoryService: CategoryService,
    private toastrService: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setStartDate();
    this.fetchProjects();
    this.loadSavings();
    this.loadLoans();

    this.subs.add(
      this.dataService.data.subscribe(data => {
        if (data.date) {
          this.date.setValue(data.date);
        }

        this.updateDateRange(false);
      })
    );

    this.subs.add(
      this.eventService.getUpdate().subscribe(() => {
        this.load();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  setStartDate(): void {
    const selectedDate: string = localStorage.getItem('selectedDate');

    let monthDate: Moment;
    if (selectedDate) {
      const obj: any = JSON.parse(selectedDate);
      if (moment.utc() < moment(obj.expired)) {
        monthDate = moment(obj.date, null, 'he');
      }
    }

    if (!monthDate || !monthDate.isValid()) {
      const date: Moment = this.helperService.getMonthStartDate();
      monthDate = moment(
        new Date(
          date.year(),
          date.month(),
          this.authenticationService.currentUserValue.user.startMonthDay
        ),
        null,
        'he'
      );
    }

    this.date = new UntypedFormControl(monthDate);
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue: Moment = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.date.setValue(normalizedMonth);
    datepicker.close();

    localStorage.setItem(
      'selectedDate',
      JSON.stringify({
        date: this.date.value,
        expired: moment.utc().add(1, 'day'),
      })
    );
    this.updateDateRange();
  }

  updateDateRange(updateData: boolean = true): void {
    if (updateData) {
      this.dataService.updatedDataSelection({ date: this.date.value });
    }

    const monthDate: Moment = moment(
      new Date(
        this.date.value.year(),
        this.date.value.month(),
        this.authenticationService.currentUserValue.user.startMonthDay
      )
    );
    this.startMonthDate = monthDate.format('DD.MM.YYYY');
    this.endMonthDate = monthDate
      .add(1, 'months')
      .add(-1, 'days')
      .format('DD.MM.YYYY');

    this.load();
    this.eventService.send({
      type: EventType.Select,
      objectType: ObjectType.Date,
      object: this.date.value,
    });
  }

  fetchProjects(): void {
    this.subs.add(
      this.projectService.list(null).subscribe(data => {
        this.projects = data.filter(x => x.type != ProjectType.AdHoc); // don't support AdHoc on phase 1
        if (this.projects.length > 0) {
          const currentProjectId: number = +(
            localStorage.getItem('currentProject') || '0'
          );

          this.currentProject = this.projects.find(
            x => x.id === currentProjectId
          );

          if (!this.currentProject) {
            this.currentProject = this.projects[0];
            localStorage.setItem(
              'currentProject',
              this.currentProject.id.toString()
            );
          }

          this.projectControl.setValue(this.currentProject.id);
          this.eventService.send({
            type: EventType.Select,
            objectType: ObjectType.Project,
            object: this.currentProject.id,
          });
        }
      })
    );
  }

  updateProject(event: any): void {
    this.currentProject = this.projects.find(x => x.id === event.value);
    localStorage.setItem('currentProject', this.currentProject.id.toString());
    this.load();
    this.eventService.send({
      type: EventType.Select,
      objectType: ObjectType.Project,
      object: this.currentProject.id,
    });
  }

  load(): void {
    this.loadTables();
  }

  loadTables(): void {
    this.isLoading = true;
    const date: Moment = this.date.value;
    this.subs.add(
      this.reportService
        .get(
          true,
          this.userId,
          date.year(),
          date.month() + 1,
          (this.currentProject || { id: null }).id
        )
        .subscribe(
          data => {
            if (this.isFirstLoad) {
              this.isFirstLoad = false;

              if (
                data.length === 0 &&
                !this.currentProject?.isBusiness &&
                !this.authenticationService.currentUserValue.user
                  .isWizardCompleted
              ) {
                this.createDefaultHomeCategories();
                return;
              }
            }

            this.incomesTable.rows = _.sortBy(
              data.filter(x => x.category.categoryType == CategoryType.Income),
              [
                x => (x.category.type === 1 ? -1 : x.category.type),
                x => x.category.name.toLowerCase(),
              ]
            );
            this.expensesTable.rows = _.sortBy(
              data.filter(x => x.category.categoryType == CategoryType.Expense),
              [
                x => (x.category.type === 1 ? -1 : x.category.type),
                x => x.category.name.toLowerCase(),
              ]
            );

            const reducer = (accumulator, currentValue) =>
              accumulator + currentValue;

            const totalIncomeAmount: number = this.incomesTable.rows
              .map(x => x.expensesSum)
              .reduce(reducer, 0);
            const totalIncomePlanning: number = this.incomesTable.rows
              .map(x => x.currentPeriodBudget)
              .reduce(reducer, 0);

            const totalExpenseAmount: number = this.expensesTable.rows
              .map(x => x.expensesSum)
              .reduce(reducer, 0);
            const totalExpensePlanning: number = this.expensesTable.rows
              .map(x => x.currentPeriodBudget)
              .reduce(reducer, 0);

            this.totalTable.rows = [
              {
                category: { name: 'יתרה חודשית' },
                currentPeriodBudget: totalIncomeAmount,
                expensesSum: totalExpenseAmount,
              },
            ];

            this.expensesTotal.expensesSum = totalExpenseAmount;
            this.expensesTotal.planning = totalExpensePlanning;

            this.incomesTotal.incomesSum = totalIncomeAmount;
            this.incomesTotal.planning = totalIncomePlanning;

            this.mainTotalTable.rows = [
              {
                name: 'סה״כ הכנסות',
                planning: totalIncomePlanning,
                amount: totalIncomeAmount,
              },
              {
                name: 'סה״כ הוצאות',
                planning: totalExpensePlanning,
                amount: totalExpenseAmount,
              },
              {
                name: 'יתרה (הכנסות פחות הוצאות)',
                planning: totalIncomePlanning - totalExpensePlanning,
                amount: totalIncomeAmount - totalExpenseAmount,
                isCalc: true,
              },
            ];

            this.isLoading = false;
          },
          () => (this.isLoading = false)
        )
    );
  }

  editCategory(event, category: CategoryModel): void {
    event.stopPropagation();
    event.preventDefault();

    const dialogRef = this.dialog.open(CategoryEditComponent, {
      data: {
        category: category,
        startMonthDate: this.date.value,
      },
      panelClass: 'main-widget-panel',
    });

    this.subs.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateDateRange();
        }
      })
    );
  }

  savings: SavingModel[] = [];
  totalSavingAmount: number = 0;
  savingsIsLoading: boolean = false;
  showSavings: boolean = false;

  loans: LoanModel[] = [];
  totalLoanAmount: number = 0;
  loansIsLoading: boolean = false;
  showLoans: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  loadSavings(): void {
    this.savingsIsLoading = true;

    this.savingService
      .list()
      .pipe(
        finalize(() => (this.savingsIsLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.savings = data;
        this.totalSavingAmount =
          _.sumBy(data, 'totalDepositsAmount') -
          _.sumBy(data, 'totalWithdrawalAmount');
      });
  }

  loadLoans(): void {
    this.loansIsLoading = true;

    this.loanService
      .list()
      .pipe(
        finalize(() => (this.loansIsLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.loans = data;
        this.totalLoanAmount = _.sumBy(data, 'totalLoanLeftAmount');
      });
  }

  editSaving(event: MouseEvent, item: SavingModel): void {
    event.stopPropagation();
    event.preventDefault();

    const dialogRef = this.dialog.open(SavingWidgetComponent, {
      width: '290px',
      data: {
        ...item,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.eventService.send({
            type: EventType.Update,
            objectType: ObjectType.Saving,
            object: { ...result },
          });
          this.loadSavings();
        }
      });
  }

  createDefaultHomeCategories(): void {
    this.dialog
      .open(CreateDefaultCategoriesComponent, {
        panelClass: 'main-widget-panel',
        closeOnNavigation: false,
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.value) {
          this.isLoading = true;
          this.categoryService
            .createDefaultHomeCategories()
            .pipe(
              finalize(() => (this.isLoading = false)),
              takeUntil(this.destroy$)
            )
            .subscribe({
              next: () => {
                this.load();
              },
              error: () => {
                this.toastrService.error('Error');
                this.createDefaultHomeCategories();
              },
            });
        }
      });
  }
}
