import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ExpenseModel, ReportFileModel } from '../_common/models';

@Injectable({ providedIn: 'root' })
export class ExpenseService {
  constructor(private http: HttpClient) { }

  save(model: ExpenseModel) {
    return this.http.post<ExpenseModel>(`api/expenses`, model);
  }

  update(model: ExpenseModel) {
    return this.http.put<ExpenseModel>(`api/expenses`, model);
  }

  delete(id: number, year: number, month: number) {
    return this.http.delete<any>(`api/expenses?id=${id}&year=${year}&month=${month}`);
  }

  list(size: number, offset: number, categoryId: number, userId: number, year: string, month: string, projectId: number, type: number = null, expensesType: number = null, search: string = null) {
    let url = `api/expenses/list/${size}/${offset}?categoryId=${categoryId}&userId=${userId}&year=${year}&month=${month}&project=${projectId}&type=${type}&expensesType=${expensesType}`;
    if (search) {
      url += `&search=${search}`;
    }

    return this.http.get<ExpenseModel[]>(url);
  }

  export(categoryId: number, userId: number, year: string, month: string, projectId: number, type: number = null, expensesType: number = null) {
    return this.http.get<ReportFileModel>(`api/expenses/export?categoryId=${categoryId}&userId=${userId}&year=${year}&month=${month}&project=${projectId}&type=${type}&expensesType=${expensesType}&addTotal=true`);
  }
}
