export class IncomeModel {
  id: number;
  createdDate: string;
  userId: number;
  categoryId: number;
  amount: number;
  description: string;
  incomeDateTime: string;
  type: TypeOfIncome;
  incomeVia: TypeIncomeVia;
  projectId: number;
}

export enum TypeOfIncome {
  Salary = 0,
  Refunds = 1,
  Gifts = 2,
  BusinessToHome1 = 3, //Transfer from business 1 to home
  BusinessToHome2 = 4, //Transfer from business 2 to home
  Business1 = 5,
  Business2 = 6,
  Other = 7
}

export enum TypeIncomeVia {
  Credit = 0,
  Check = 1,
  BankTransfer = 2,
  Cash = 3,
  Other = 4
}
