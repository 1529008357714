import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PaymentModel } from '../_common/models/saving';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private http: HttpClient) { }

  save(model: PaymentModel) {
    return this.http.post<PaymentModel>(`api/v1/payment`, model);
  }

  update(model: PaymentModel) {
    return this.http.put<PaymentModel>(`api/v1/payment`, model);
  }

  delete(id: number, year: number, month: number) {
    return this.http.delete<any>(`api/v1/payment?id=${id}&year=${year}&month=${month}`);
  }

  list(offset: number, size: number, savingId: number, year: string, month: string, userId?: number,) {
    let query = `offset=${offset}&savingId=${savingId}&year=${year}&month=${month}`;

    if (size) {
      query += `size=${size}`;
    }

    if (userId) {
      query += `size=${userId}`;
    }

    return this.http.get<PaymentModel[]>(`api/v1/payment/list?${query}`);
  }
}
