import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MY_FORMATS_DATE } from '@app/_common/dateFormats';
import { Subject } from 'rxjs';
import { Moment } from 'moment';
import { LoanModel } from '@app/_common/models/loan';
import { MyErrorStateMatcher } from '@app/_helpers';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AuthenticationService,
  EventService,
  EventType,
  HelperService,
  LoanService,
  ObjectType,
} from '@app/_services';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loan-widget',
  templateUrl: './loan-widget.component.html',
  styleUrls: ['./loan-widget.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DATE },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class LoanWidgetComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public loan: LoanModel;
  public startMonthDate: Moment;

  matcher = new MyErrorStateMatcher();

  loanForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loanService: LoanService,
    private eventService: EventService,
    private toastr: ToastrService,
    @Optional() public dialogRef: MatDialogRef<LoanWidgetComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: LoanModel
  ) {
    this.loan = data;
  }

  ngOnInit(): void {
    this.initLoan();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initLoan() {
    if (this.loan) {
      this.loanForm = this.formBuilder.group({
        name: [this.loan.name, Validators.required],
        description: [this.loan.description],
        loanBalance: [this.loan.loanBalance, Validators.required],
        originalBalance: [this.loan.originalBalance],
        repaymentDate: [this.loan.repaymentDate],
      });
    } else {
      this.loanForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        loanBalance: [0, Validators.required],
        originalBalance: [null],
        repaymentDate: [null],
      });
    }
  }

  submit(event: any): void {
    if (this.loanForm.invalid) {
      return;
    }

    if (this.loan) {
      const loan = Object.assign(this.loan, this.loanForm.value);

      this.loanService
        .update(loan)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.resetForm(event);
            this.eventService.send({
              type: EventType.Update,
              objectType: ObjectType.Loan,
              object: { ...loan },
            });
            if (this.dialogRef) {
              this.dialogRef.close(true);
            }
            this.toastr.success('עודכן');
          },
          err => {
            this.toastr.error('Error');
            console.warn(err);
          }
        );
    } else {
      const loan: LoanModel = this.loanForm.value;
      this.loanService
        .save(loan)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.resetForm(event);
            this.eventService.send({
              type: EventType.Create,
              objectType: ObjectType.Loan,
              object: data,
            });
            if (this.dialogRef) {
              this.dialogRef.close(true);
            }
            this.toastr.success('עודכן');
          },
          err => {
            this.toastr.error('Error');
            console.warn(err);
          }
        );
    }
  }

  cancelHandler(event: any): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  resetForm(event: any): void {
    const oldValue = this.loanForm.value as LoanModel;
    oldValue.name = '';
    oldValue.description = '';
    oldValue.loanBalance = 0;
    oldValue.originalBalance = null;
    oldValue.repaymentDate = null;

    event.target.reset();
    this.loanForm.reset(oldValue);
  }
}
