import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProjectModel } from '../_common/models';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(private http: HttpClient) { }

  list(userId: number) {
    return this.http.get<ProjectModel[]>(`api/projects/list?userId=${userId}`);
  }
}
