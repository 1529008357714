import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankAccountModel } from '../_common/models';

@Injectable({ providedIn: 'root' })
export class BankAccountService {
  constructor(private http: HttpClient) {}

  get(projectId: number, year: number, month: number) {
    return this.http.get<BankAccountModel>(
      `api/bankAccount/${projectId}/${year}/${month}`
    );
  }

  update(model: BankAccountModel) {
    return this.http.put<BankAccountModel>(`api/bankAccount`, model);
  }
}
