import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ReportService,
  ProjectService,
  EventService,
  ObjectType,
  EventType,
} from '@app/_services';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { ProjectModel, ProjectType } from '@app/_common/models';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { finalize, takeUntil } from 'rxjs/operators';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-years-reports',
  templateUrl: './years-reports.component.html',
  styleUrls: ['./years-reports.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class YearsReportsComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  public projectControl = new UntypedFormControl();
  projects: ProjectModel[] = [];
  currentProject: ProjectModel;

  range: FormGroup = new FormGroup({
    startDate: new FormControl(moment().add(-1, 'year').startOf('year')),
    endDate: new FormControl(moment().endOf('year')),
  });

  public selectedStartYear: number;
  public selectedEndYear: number;

  displayedColumns: string[] = [];
  columns: any[] = [];

  dataSource: any[] = [];

  public isLoading: boolean = false;

  constructor(
    private reportService: ReportService,
    private projectService: ProjectService,
    private eventService: EventService
  ) {
    this.eventService
      .getUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (
          (event.objectType === ObjectType.Expense ||
            event.object === ObjectType.Income) &&
          event.type === EventType.Create
        ) {
          this.load();
        }
      });

    this.selectedStartYear = this.range.get('startDate').value.format('YYYY');
    this.selectedEndYear = this.range.get('endDate').value.format('YYYY');
  }

  ngOnInit() {
    this.fetchProjects();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchProjects(): void {
    this.isLoading = true;
    this.projectService
      .list(null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.projects = data.filter(x => x.type != ProjectType.AdHoc); // don't support AdHoc on phase 1
          if (this.projects.length > 0) {
            const currentProjectId: number = +(
              localStorage.getItem('currentProject') || '0'
            );

            this.currentProject = this.projects.find(
              x => x.id === currentProjectId
            );

            if (!this.currentProject) {
              this.currentProject = this.projects[0];
              localStorage.setItem(
                'currentProject',
                this.currentProject.id.toString()
              );
            }

            this.projectControl.setValue(this.currentProject.id);
            this.load();
          }
        },
        () => (this.isLoading = false)
      );
  }

  updateProject(event: any): void {
    this.currentProject = this.projects.find(x => x.id === event.value.id);
    localStorage.setItem('currentProject', this.currentProject.id.toString());
  }

  chosenYearHandler(
    normalizedYear: Moment,
    startDate: MatDatepicker<any>,
    endDate: MatDatepicker<any>
  ) {
    if (startDate != null) {
      this.selectedStartYear = normalizedYear.year();

      if (this.selectedEndYear < this.selectedStartYear) {
        this.selectedEndYear = this.selectedStartYear;
      }
      startDate.close();
      endDate.open();
    } else {
      this.selectedEndYear = normalizedYear.year();

      if (this.selectedEndYear < this.selectedStartYear) {
        this.selectedStartYear = this.selectedEndYear;
      }

      endDate.close();
    }
  }

  load(): void {
    this.isLoading = true;
    this.reportService
      .getYearsReportByCategories({
        startYear: this.selectedStartYear,
        endYear: this.selectedEndYear,
        projectId: this.currentProject.id,
      })
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.columns = data.columns;
        this.dataSource = data.rows;

        this.displayedColumns = this.columns.map(x => x.name);
      });
  }

  download(): void {
    this.isLoading = true;
    this.reportService
      .generateYearsReportByCategories({
        startYear: this.selectedStartYear,
        endYear: this.selectedEndYear,
        projectId: this.currentProject.id,
      })
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        window.open(data.url, '_blank');
      });
  }
}
