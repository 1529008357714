import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MY_FORMATS_DATE } from '@app/_common/dateFormats';
import { SavingModel } from '@app/_common/models/saving';
import { MyErrorStateMatcher } from '@app/_helpers';
import {
  AuthenticationService,
  EventService,
  EventType,
  HelperService,
  ObjectType,
  SavingService,
} from '@app/_services';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-saving-widget',
  templateUrl: './saving-widget.component.html',
  styleUrls: ['./saving-widget.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DATE },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class SavingWidgetComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  public saving: SavingModel;
  public startMonthDate: Moment;

  matcher = new MyErrorStateMatcher();

  savingForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private savingService: SavingService,
    private eventService: EventService,
    private toastr: ToastrService,
    private helperService: HelperService,
    @Optional() public dialogRef: MatDialogRef<SavingWidgetComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SavingModel
  ) {
    this.saving = data;
  }

  ngOnInit(): void {
    this.initSaving();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initSaving() {
    if (this.saving) {
      this.savingForm = this.formBuilder.group({
        name: [this.saving.name, Validators.required],
        description: [this.saving.description],
        startingAmount: [this.saving.startingAmount, Validators.required],
        goalAmount: [this.saving.goalAmount],
        goalDate: [this.saving.goalDate],
      });
    } else {
      this.savingForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        startingAmount: [null],
        goalAmount: [null],
        goalDate: [null],
      });
    }
  }

  submit(event: any): void {
    if (this.savingForm.invalid) {
      return;
    }

    if (this.saving) {
      const saving = Object.assign(this.saving, this.savingForm.value);

      this.savingService
        .update(saving)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.resetForm(event);
            this.eventService.send({
              type: EventType.Update,
              objectType: ObjectType.Saving,
              object: { ...saving },
            });
            if (this.dialogRef) {
              this.dialogRef.close(true);
            }
            this.toastr.success('עודכן');
          },
          err => {
            this.toastr.error('Error');
            console.warn(err);
          }
        );
    } else {
      const saving: SavingModel = this.savingForm.value;

      if (saving.startingAmount === null) {
        saving.startingAmount = 0;
      }

      this.savingService
        .save(saving)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.resetForm(event);
            this.eventService.send({
              type: EventType.Create,
              objectType: ObjectType.Saving,
              object: data,
            });
            if (this.dialogRef) {
              this.dialogRef.close(true);
            }
            this.toastr.success('עודכן');
          },
          err => {
            this.toastr.error('Error');
            console.warn(err);
          }
        );
    }
  }

  cancelHandler(event: any): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  resetForm(event: any): void {
    const oldValue = this.savingForm.value as SavingModel;
    oldValue.name = '';
    oldValue.description = '';
    oldValue.startingAmount = 0;
    oldValue.goalAmount = null;
    oldValue.goalDate = null;

    event.target.reset();
    this.savingForm.reset(oldValue);
  }
}
