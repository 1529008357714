import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventService {
  private subject = new Subject<any>();

  getUpdate(): Observable<{
    type: EventType;
    objectType: ObjectType;
    object: any;
  }> {
    return this.subject.asObservable();
  }

  send(event: { type: EventType; objectType: ObjectType; object: any }): void {
    this.subject.next(event);
  }
}

export enum EventType {
  Create,
  Update,
  Delete,
  Select,
}

export enum ObjectType {
  Category,
  Expense,
  Income,
  Project,
  Date,
  Saving,
  SavingPayment,
  Loan,
  LoanPayment,
}
