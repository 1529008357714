import { CategoryModel } from '.';

export class CategoryReportModel {
  category: CategoryModel;
  expensesSum: number;
  yearlyExpensesSum: number;
  yearlyIncomesSum: number;
  currentPeriodBudget: number;

  public constructor(init?: Partial<CategoryReportModel>) {
    Object.assign(this, init);
  }
}
