import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  AuthenticationService,
  HelperService,
  BankAccountService,
  ProjectService,
} from '@app/_services';
import { MyErrorStateMatcher } from '@app/_helpers';
import { Subject } from 'rxjs';
import {
  BankAccountModel,
  ProjectModel,
  ProjectType,
} from '@app/_common/models';
import { ToastrService } from 'ngx-toastr';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';
import { finalize, takeUntil } from 'rxjs/operators';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class BankAccountComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  bankAccountForm: UntypedFormGroup;
  date: UntypedFormControl;

  selectedMonth: string = '';
  public startMonthDate: string;
  public endMonthDate: string;

  public projectControl = new UntypedFormControl();
  projects: ProjectModel[] = [];

  matcher = new MyErrorStateMatcher();

  public isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private helperService: HelperService,
    private projectService: ProjectService,
    private bankAccountService: BankAccountService,
    private toastr: ToastrService
  ) {
    this.date = new UntypedFormControl(this.helperService.getMonthStartDate());
  }

  ngOnInit() {
    this.bankAccountForm = this.formBuilder.group({
      startStatus: ['', Validators.required],
      endStatus: ['', Validators.required],
    });

    this.fetchProjects();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchProjects(): void {
    this.isLoading = true;
    this.projectService
      .list(null)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.projects = data.filter(x => x.type != ProjectType.AdHoc); // don't support AdHoc on phase 1
        if (this.projects.length > 0) {
          const currentProjectId: number = +(
            localStorage.getItem('currentProject') || '0'
          );

          const currentProject: ProjectModel = this.projects.find(
            x => x.id === currentProjectId
          );

          if (currentProject) {
            this.projectControl.setValue(currentProject.id);
          } else {
            this.projectControl.setValue(this.projects[0]);
            localStorage.setItem(
              'currentProject',
              this.projects[0].id.toString()
            );
          }

          this.updateDateRange();
        }
      });
  }

  updateProject(event: any): void {
    this.projectControl.setValue(event.value);
    localStorage.setItem('currentProject', event.value.toString());
    this.loadBankStatus();
  }

  updateDateRange(): void {
    const monthDate: Moment = moment(
      new Date(
        this.date.value.year(),
        this.date.value.month(),
        this.authenticationService.currentUserValue.user.startMonthDay
      ),
      null,
      'he'
    );
    this.selectedMonth = monthDate.format('MMM YY');
    this.startMonthDate = monthDate.format('DD.MM.YYYY');
    this.endMonthDate = monthDate
      .add(1, 'months')
      .add(-1, 'days')
      .format('DD.MM.YYYY');

    this.loadBankStatus();
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue: Moment = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.date.setValue(normalizedMonth);
    datepicker.close();

    this.updateDateRange();
  }

  loadBankStatus(): void {
    this.isLoading = true;
    this.bankAccountService
      .get(
        this.projectControl.value,
        +this.date.value.format('Y'),
        +this.date.value.format('M')
      )
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        data => {
          this.bankAccountForm.reset(data);

          Object.keys(this.bankAccountForm.controls).forEach(key => {
            this.bankAccountForm.get(key).setErrors(null);
          });
        },
        err => {
          console.warn(err);
        }
      );
  }

  submit(): void {
    if (this.bankAccountForm.invalid) {
      return;
    }

    const model: BankAccountModel = this.bankAccountForm.value;
    model.projectId = this.projectControl.value;
    model.year = +this.date.value.format('Y');
    model.month = +this.date.value.format('M');

    this.isLoading = true;
    this.bankAccountService
      .update(model)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        data => {
          this.toastr.success('הנתונים נשמרו');
        },
        err => {
          this.toastr.error('Error');
          console.warn(err);
        }
      );
  }
}
