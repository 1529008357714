export class ProjectModel {
  id: number;
  name: string;
  userId: number;
  isSendExpensesPushNotification: boolean;
  isBusiness: boolean;
  type: ProjectType;
  adHocType: AdHocProjectType;
  startDate: string;
  endDate: string;
}

export enum ProjectType { Basic = 0, AdHoc = 1 }

export enum AdHocProjectType { BuildingAHouseRenovation = 0, MovingToANewApartment = 1, Vacation = 2, Event = 3, Other = 4 }
