import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as _ from "lodash";

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumbersOnlyDirective {

  @Input('numberRange') numberRange: string = 'positive';

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');


    if (this.numberRange === 'all' && _.startsWith(initalValue, '-')) {
      this._el.nativeElement.value = '-' + this._el.nativeElement.value;
    }

    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
