import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StateService {
  public projectId?: number;
  public categoryId?: number;

  setValues(projectId?: number, categoryId?: number) {
    this.projectId = projectId;
    this.categoryId = categoryId;
  }
}
