import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  Optional,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ProjectModel,
  CategoryModel,
  ExpenseModel,
  CategoryType,
  ExpensesType,
  TypeOfPay,
  IncomeModel,
  ProjectType,
} from '@app/_common/models';
import {
  ProjectService,
  ReportService,
  ExpenseService,
  IncomeService,
  EventService,
  ObjectType,
  EventType,
  SettingsService,
  StateService,
} from '@app/_services';
import { Subject } from 'rxjs';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MyErrorStateMatcher } from '@app/_helpers';
import * as _ from 'lodash';
import { MY_FORMATS_DATE } from '@app/_common/dateFormats';
import { SuccessUpdatedComponent } from '../success-updated/success-updated.component';
import { finalize, takeUntil } from 'rxjs/operators';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-expense-widget',
  templateUrl: './expense-widget.component.html',
  styleUrls: ['./expense-widget.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DATE },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class ExpenseWidgetComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  expenseForm: UntypedFormGroup;
  editTypeForm: UntypedFormGroup;

  matcher = new MyErrorStateMatcher();

  categories: CategoryModel[] = [];
  filteredCategories: CategoryModel[] = [];

  types: {
    id: string;
    value: string;
    enumValue: any;
    isEditable: boolean;
    order: number;
  }[] = [
    {
      id: 'CreditCard',
      value: 'כרטיס אשראי 1',
      enumValue: TypeOfPay.CreditCard,
      isEditable: true,
      order: 0,
    },
    {
      id: 'CreditCard2',
      value: 'כרטיס אשראי 2',
      enumValue: TypeOfPay.CreditCard2,
      isEditable: true,
      order: 1,
    },
    {
      id: 'CreditCard3',
      value: 'כרטיס אשראי 3',
      enumValue: TypeOfPay.CreditCard3,
      isEditable: true,
      order: 2,
    },
    {
      id: 'CreditCard4',
      value: 'כרטיס אשראי 4',
      enumValue: TypeOfPay.CreditCard4,
      isEditable: true,
      order: 3,
    },
    {
      id: 'Cash',
      value: 'מזומן',
      enumValue: TypeOfPay.Cash,
      isEditable: false,
      order: 4,
    },
    {
      id: 'Other1',
      value: 'אחר',
      enumValue: TypeOfPay.Other1,
      isEditable: true,
      order: 5,
    },
    {
      id: 'Other2',
      value: 'אחר 2',
      enumValue: TypeOfPay.Other2,
      isEditable: true,
      order: 6,
    },
    {
      id: 'Other3',
      value: 'אחר 3',
      enumValue: TypeOfPay.Other3,
      isEditable: true,
      order: 7,
    },
    {
      id: 'Other4',
      value: 'אחר 4',
      enumValue: TypeOfPay.Other4,
      isEditable: true,
      order: 8,
    },
    {
      id: 'Refundable',
      value: 'יתקבל החזר',
      enumValue: TypeOfPay.Refundable,
      isEditable: false,
      order: 100,
    },
    {
      id: 'BankTransfer',
      value: 'העברה',
      enumValue: TypeOfPay.BankTransfer,
      isEditable: false,
      order: 101,
    },
    {
      id: 'Check',
      value: 'שיק',
      enumValue: TypeOfPay.Check,
      isEditable: false,
      order: 102,
    },
  ];

  allTypes: {
    id: string;
    value: string;
    enumValue: any;
    isEditable: boolean;
  }[] = [];

  incomeTypes: { id: string; value: string }[] = [
    {
      id: 'Other',
      value: 'אחר',
    },
    {
      id: 'FixedIncome',
      value: 'הכנסה קבועה',
    },
  ];

  incomeViaTypes: { id: string; value: string }[] = [
    {
      id: 'Credit',
      value: 'סליקת אשראי',
    },
    {
      id: 'Check',
      value: 'שיק',
    },
    {
      id: 'BankTransfer',
      value: 'העברה בנקאית',
    },
    {
      id: 'Cash',
      value: 'מזומן',
    },
  ];

  countOfTimesArray: number[] = [...Array(36).keys()].map(x => x + 1);

  projects: ProjectModel[] = [];
  currentProject: ProjectModel;

  loading: boolean = false;

  tab: string = 'expense';
  item: ExpenseModel | IncomeModel;

  @ViewChild('editTypeRef', { static: true }) editTypeRef: TemplateRef<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private reportService: ReportService,
    private projectService: ProjectService,
    private expenseService: ExpenseService,
    private incomeService: IncomeService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private stateService: StateService,
    private toastr: ToastrService,
    @Optional() public dialogRef: MatDialogRef<ExpenseWidgetComponent>,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      categoryType: CategoryType;
      item: ExpenseModel | IncomeModel;
    }
  ) {
    if (data) {
      if (data.categoryType === CategoryType.Expense) {
        this.tab = 'expense';
      } else {
        this.tab = 'income';
      }

      this.item = data.item;
    }
  }

  ngOnInit() {
    this.expenseForm = this.formBuilder.group({
      amount: [null, Validators.required],
      description: [''],
      spenderName: [''],
      date: [moment(), Validators.required],
      categoryId: ['', Validators.required],
      type: [this.types[0].id, Validators.required], // for expense only
      countOfTimes: [1, Validators.required],
      incomeVia: [this.incomeTypes[0].id, Validators.required], // for income only
      projectId: ['', Validators.required],
    });

    this.editTypeForm = this.formBuilder.group({
      type: [null, Validators.required],
      typeText: ['', Validators.required],
    });

    if (this.item) {
      this.expenseForm.reset(this.item);

      if (this.tab === 'expense') {
        const expense: ExpenseModel = <ExpenseModel>this.item;
        this.expenseForm.controls['date'].setValue(
          moment.utc(expense.expenseDateTime).local()
        );

        if (expense.typeOfExpense === ExpensesType.Expense) {
          this.expenseForm.controls['type'].setValue(
            this.types.find(x => x.enumValue === expense.type).id
          );
        } else if (expense.typeOfExpense === ExpensesType.Payments) {
          this.expenseForm.controls['type'].setValue('Payments');
        } else if (expense.typeOfExpense === ExpensesType.DirectDebit) {
          this.expenseForm.controls['type'].setValue('DirectDebit');
        }
      } else {
        this.expenseForm.controls['date'].setValue(
          moment.utc((<IncomeModel>this.item).incomeDateTime).local()
        );
      }

      if (!this.expenseForm.value.incomeVia) {
        this.expenseForm.controls['incomeVia'].setValue(this.incomeTypes[0].id);
      }

      if (!this.expenseForm.value.countOfTimes) {
        this.expenseForm.controls['countOfTimes'].setValue(1);
      }
    }

    this.updateTypes();
    this.fetchProjects();
    this.initEvents();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchProjects(): void {
    this.projectService
      .list(null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.projects = data.filter(x => x.type != ProjectType.AdHoc); // don't support AdHoc on phase 1
        if (this.projects.length > 0) {
          if (this.item) {
            this.currentProject = this.projects.find(
              x => x.id === this.item.projectId
            );
          } else {
            const currentProjectId: number = +(
              localStorage.getItem('currentProject') || '0'
            );

            this.currentProject = this.projects.find(
              x => x.id === currentProjectId
            );

            if (!this.currentProject) {
              this.currentProject = this.projects[0];
            }
          }

          this.expenseForm.controls['projectId'].setValue(
            this.currentProject.id
          );

          this.fetchCategories();
        }
      });
  }

  fetchCategories(): void {
    this.reportService
      .get(true, null, null, null, this.currentProject.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.categories = data.map(x => x.category);

        if (this.tab === 'expense') {
          const expenseCategories: CategoryModel[] = _.sortBy(
            this.categories.filter(
              x => x.categoryType === CategoryType.Expense
            ),
            x => x.name.toLowerCase()
          );

          this.filteredCategories = expenseCategories.filter(
            x => x.projectId == this.currentProject.id
          );
        } else {
          this.updateIncomesCategories();
        }

        if (
          this.stateService.categoryId &&
          this.filteredCategories.some(
            x => x.id === this.stateService.categoryId
          )
        ) {
          this.expenseForm.controls['categoryId'].setValue(
            this.stateService.categoryId
          );
        }
      });
  }

  initEvents(): void {
    this.eventService
      .getUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.objectType === ObjectType.Category) {
          if (event.type === EventType.Select) {
            setTimeout(
              () => {
                if (this.projects.some(x => x.id === event.object.projectId)) {
                  if (this.currentProject.id === event.object.projectId) {
                    const category: CategoryModel = this.categories.find(
                      x => x.id === event.object.id
                    );
                    if (category.categoryType === CategoryType.Expense) {
                      this.createExpense();
                    } else {
                      this.createIncome();
                    }

                    this.expenseForm.controls['categoryId'].setValue(
                      event.object.id
                    );
                  } else {
                    this.updateProject(
                      { value: event.object.projectId },
                      event.object.id
                    );
                  }
                }
              },
              this.projects.length === 0 ? 500 : 0
            );
          } else if (
            event.type === EventType.Create ||
            event.type === EventType.Update
          ) {
            const category: CategoryModel = <CategoryModel>event.object;
            if (category.projectId === this.currentProject.id) {
              let categoryId: number | null = null;
              if (this.expenseForm.value.categoryId === category.id) {
                categoryId = category.id;
              }

              this.updateProject({ value: category.projectId }, categoryId);
            }
          } else if (event.type === EventType.Delete) {
            const category: CategoryModel = <CategoryModel>event.object;
            if (category.projectId === this.currentProject.id) {
              this.categories = this.categories.filter(
                x => x.id != category.id
              );
              this.filteredCategories = this.filteredCategories.filter(
                x => x.id != category.id
              );
              if (this.expenseForm.value.categoryId === category.id) {
                this.expenseForm.controls['categoryId'].setValue('');
              }
            }
          }
        }

        if (
          event.objectType === ObjectType.Project &&
          event.type === EventType.Select
        ) {
          if (this.projects.length > 0) {
            this.expenseForm.controls['projectId'].setValue(event.object);
            this.updateProject({ value: event.object });
          }
        }
      });
  }

  createExpense(): void {
    this.tab = 'expense';
    this.expenseForm.controls['spenderName'].setValue('');
    this.expenseForm.controls['countOfTimes'].setValue(1);
    this.updateExpenseCategories();
  }

  updateExpenseCategories(): void {
    const expenseCategories: CategoryModel[] = _.sortBy(
      this.categories.filter(x => x.categoryType === CategoryType.Expense),
      x => x.name.toLowerCase()
    );
    this.filteredCategories = expenseCategories.filter(
      x => x.projectId == this.currentProject.id
    );
  }

  createIncome(): void {
    this.tab = 'income';
    this.expenseForm.controls['spenderName'].setValue('default');
    this.expenseForm.controls['countOfTimes'].setValue(1);
    this.updateIncomesCategories();
  }

  updateIncomesCategories(): void {
    const incomeCategories: CategoryModel[] = _.sortBy(
      this.categories.filter(x => x.categoryType === CategoryType.Income),
      x => x.name.toLowerCase()
    );
    this.filteredCategories = incomeCategories.filter(
      x => x.projectId == this.currentProject.id
    );
  }

  updateProject(event: any, categoryId?: number): void {
    this.currentProject = this.projects.find(x => x.id === event.value);
    if (
      this.currentProject.type === ProjectType.AdHoc &&
      this.types.some(x => x.id === this.expenseForm.value.type) === false
    ) {
      this.expenseForm.controls['type'].setValue(this.types[0].id);
    }

    this.reportService
      .get(true, null, null, null, this.currentProject.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.categories = data.map(x => x.category);
        // update categories list
        if (this.tab === 'expense') {
          this.updateExpenseCategories();
        } else {
          this.updateIncomesCategories();
        }

        if (categoryId) {
          this.expenseForm.controls['categoryId'].setValue(categoryId);
        } else {
          this.expenseForm.controls['categoryId'].setValue('');
        }

        if (!this.currentProject.isBusiness) {
          this.expenseForm.controls['incomeVia'].setValue(
            this.incomeTypes[0].id
          );
        }
      });
  }

  onSubmitExpense(event: any): void {
    if (this.tab === 'income') {
      this.expenseForm.controls['spenderName'].setValue('default');
    }

    if (this.expenseForm.invalid || this.loading) {
      return;
    }

    // const service = this.tab === 'expense' ? this.expenseService : this.incomeService;
    if (this.tab === 'expense') {
      const model: ExpenseModel = this.expenseForm.value;
      model.expenseDateTime = this.expenseForm.value.date;

      if (this.expenseForm.value.type === 'Payments') {
        model.type = TypeOfPay.CreditCard;
        model.typeOfExpense = ExpensesType.Payments;
        model.totalAmount = model.amount;
      } else if (this.expenseForm.value.type === 'DirectDebit') {
        model.type = TypeOfPay.CreditCard;
        model.typeOfExpense = ExpensesType.DirectDebit;
      }

      if (model.typeOfExpense != ExpensesType.Payments) {
        this.expenseForm.value.countOfTimes = null;
      }

      this.loading = true;
      if (this.item) {
        const expense = Object.assign(this.item, model);
        this.expenseService
          .update(expense)
          .pipe(
            finalize(() => (this.loading = false)),
            takeUntil(this.destroy$)
          )
          .subscribe(
            data => {
              this.eventService.send({
                type: EventType.Update,
                objectType: ObjectType.Expense,
                object: data,
              });
              //this.toastr.success('עודכן');
              this.showSuccessToast();
              this.dialogRef.close(true);
            },
            err => {
              this.toastr.error('Error');
              console.warn(err);
            }
          );
      } else {
        this.expenseService
          .save(model)
          .pipe(
            finalize(() => (this.loading = false)),
            takeUntil(this.destroy$)
          )
          .subscribe(
            data => {
              this.eventService.send({
                type: EventType.Create,
                objectType: ObjectType.Expense,
                object: data,
              });
              //this.toastr.success('עודכן');
              this.showSuccessToast();
            },
            err => {
              this.toastr.error('Error');
              console.warn(err);
            }
          );
      }
    } else if (this.tab === 'income') {
      const model: IncomeModel = this.expenseForm.value;
      model.incomeDateTime = this.expenseForm.value.date;

      model.type = null;

      this.loading = true;
      if (this.item) {
        const income = Object.assign(this.item, model);
        this.incomeService
          .update(income)
          .pipe(
            finalize(() => (this.loading = false)),
            takeUntil(this.destroy$)
          )
          .subscribe(
            () => {
              //this.toastr.success('עודכן');
              this.showSuccessToast();
            },
            err => {
              this.toastr.error('Error');
              console.warn(err);
            }
          );
      } else {
        this.incomeService
          .save(model)
          .pipe(
            finalize(() => (this.loading = false)),
            takeUntil(this.destroy$)
          )
          .subscribe(
            data => {
              this.eventService.send({
                type: EventType.Create,
                objectType: ObjectType.Income,
                object: data,
              });
              this.showSuccessToast();
            },
            err => {
              this.toastr.error('Error');
              console.warn(err);
            }
          );
      }
    }
  }

  cancel(event): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  resetForm(event: any): void {
    const oldValue = this.expenseForm.value;
    oldValue.amount = null;
    oldValue.description = '';
    oldValue.spenderName = '';
    oldValue.date = moment();
    oldValue.countOfTimes = 1;

    event.target.reset();
    this.expenseForm.reset(oldValue);

    if (!this.expenseForm.value.incomeVia) {
      this.expenseForm.controls['incomeVia'].setValue(this.incomeTypes[0].id);
    }

    if (!this.expenseForm.value.type) {
      this.expenseForm.controls['type'].setValue(this.types[0].id);
    }
  }

  updateTypes(): void {
    this.settingsService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        data.forEach(value => {
          const type = this.types.find(x => x.enumValue == value.type);
          if (type) {
            type.value = value.name;
          }
        });
      });

    this.allTypes = _.orderBy(
      [
        ...this.types,
        {
          id: 'Payments',
          value: 'תשלומים',
          enumValue: ExpensesType.Payments,
          isEditable: false,
          order: 10,
        },
        {
          id: 'DirectDebit',
          value: 'חיוב קבוע',
          enumValue: ExpensesType.DirectDebit,
          isEditable: false,
          order: 11,
        },
      ],
      ['order']
    );
  }

  editTypeDialog: MatDialogRef<any>;
  editType(
    event: any,
    type: { id: string; value: string; enumValue: any; isEditable: boolean }
  ): void {
    event.stopPropagation();
    event.preventDefault();

    this.editTypeForm.controls['type'].setValue(type);
    this.editTypeForm.controls['typeText'].setValue(type.value);
    this.editTypeDialog = this.dialog.open(this.editTypeRef);
  }

  onSubmitType(): void {
    if (this.editTypeForm.invalid) {
      return;
    }

    const model = {
      type: this.editTypeForm.controls['type'].value.enumValue,
      name: this.editTypeForm.controls['typeText'].value,
    };

    this.settingsService
      .update(model)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.updateTypes();
          this.toastr.success('עודכן');
          this.editTypeDialog.close();
        },
        err => {
          this.toastr.error('Error');
          console.warn(err);
        }
      );
  }

  closeTypeDialog(event: any): void {
    event.stopPropagation();
    event.preventDefault();

    if (!this.editTypeDialog) {
      return;
    }

    this.editTypeDialog.close();
  }

  showSuccessToast(): void {
    this.dialogRef.close(true);
    this.dialog.open(SuccessUpdatedComponent, {
      panelClass: 'main-widget-panel',
      position: { top: '104px' },
    });
  }
}
