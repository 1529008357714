import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SavingComponent } from './saving/saving.component';
import { SavingsComponent } from './savings.component';

const routes: Routes = [
  {
    path: '',
    component: SavingsComponent
  },
  {
    path: ':id',
    component: SavingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SavingsRoutingModule { }
