import { Directive, ElementRef, Input, Pipe, PipeTransform, Renderer2 } from '@angular/core';

@Directive({ selector: '[balanceColor]' })
export class BalanceColorDirective {
  @Input('balanceColor') balance: number;

  _balance: number;
  get balanceColor(): number {
    return this._balance;
  }
  @Input() set balanceColor(value: number) {
    this._balance = value;
    let color = '#000';
    if (value > 0) {
      color = '#128102';
    } else if (value < 0) {
      color = '#ff0000';
    }

    this.renderer.setStyle(this.elem.nativeElement, 'color', color);
  }

  constructor(private elem: ElementRef, private renderer: Renderer2) {

  }
}
