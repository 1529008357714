import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoryModel } from '../_common/models';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  constructor(private http: HttpClient) {}

  get(id: number) {
    return this.http.get<CategoryModel>(`api/categories/${id}`);
  }

  save(category: CategoryModel) {
    return this.http.post<CategoryModel>(`api/categories`, category);
  }

  update(category: CategoryModel) {
    return this.http.put<CategoryModel>(`api/categories`, category);
  }

  delete(id: number) {
    return this.http.delete(`api/categories?id=${id}`);
  }

  statsExpensesList(projectId: number) {
    return this.http.get<CategoryModel>(
      `api/categories/statsExpensesList?project=${projectId}`
    );
  }

  createDefaultHomeCategories() {
    return this.http.post('api/categories/createDefaultHomeCategories', {});
  }
}
