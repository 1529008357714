export interface PaymentModel {
  id: number,
  userId?: number,
  type: SavingPaymentType,
  depositType: SavingDepositType,
  amount: number,
  savingId: number,
  description: string,
  dateTime: string,
  isEditable: boolean,
  isFullAmount: boolean
}

export enum SavingPaymentType {
  Deposit = 0,
  Withdrawal = 1
}

export enum SavingDepositType {
  Deposit = 0,
  FixedMonthlyDeposit = 1,
  YieldUpdateShekels = 2
}
