import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { AuthenticationService } from './authentication.service';

const moment = _rollupMoment || _moment;

@Injectable({ providedIn: 'root' })
export class HelperService {
  constructor(private authenticationService: AuthenticationService) {}

  getMonthStartDate(date?: Moment): Moment {
    if (!date) {
      date = moment();
    }

    if (
      this.authenticationService.currentUserValue.user.startMonthDay >
      date.date()
    ) {
      date.add(-1, 'months');
    }

    return moment(
      new Date(
        date.year(),
        date.month(),
        this.authenticationService.currentUserValue.user.startMonthDay
      ),
      null,
      'he'
    );
  }
}
