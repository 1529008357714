import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SavingsRoutingModule } from './savings-routing.module';
import { SavingsComponent } from './savings.component';
import { SavingWidgetComponent } from './saving-widget/saving-widget.component';
import { PaymentWidgetComponent } from './payment-widget/payment-widget.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SavingComponent } from './saving/saving.component';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@app/_shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [
        SavingsComponent,
        SavingWidgetComponent,
        PaymentWidgetComponent,
        SavingComponent
    ],
    imports: [
        CommonModule,
        SavingsRoutingModule,
        TranslateModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatCheckboxModule,
        MatButtonModule,
        SharedModule
    ],
    exports: [
        PaymentWidgetComponent
    ]
})
export class SavingsModule { }
