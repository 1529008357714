import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User, UserModel } from '../_common/models';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<User[]>(`api/users`);
  }

  agreeTermsOfUse() {
    return this.http.put<any>(`api/users/agreeTermsOfUse`, {});
  }

  forgotPassword(model: { email: string }) {
    return this.http.post<any>(`api/users/forgotPassword`, model);
  }

  getMe() {
    return this.http.get<UserModel>(`api/users/me`);
  }

  update(user: UserModel) {
    return this.http.put<UserModel>(`api/users`, user);
  }
}
