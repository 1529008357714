import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  CategoryType,
  CategoryReportModel,
  ExpenseModel,
  IncomeModel,
} from '@app/_common/models';
import {
  AuthenticationService,
  ExpenseService,
  ReportService,
  HelperService,
  EventService,
  EventType,
  ObjectType,
  IncomeService,
  CategoryService,
  DataService,
  StateService,
} from '@app/_services';
import * as _ from 'lodash';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { Subject } from 'rxjs';
import { ExpenseWidgetComponent } from '@app/_shared/expense-widget/expense-widget.component';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  takeUntil,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryEditComponent } from '@app/_shared/category-edit/category-edit.component';
import { DeleteDialogComponent } from '@app/_shared/delete-dialog/delete-dialog.component';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class CategoryComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  private id: number;
  public categoryReport: CategoryReportModel = new CategoryReportModel();

  currentPeriodBudget: number = 0;
  expensesSum: number = 0;

  public date: UntypedFormControl;
  public selectedMonth: string = '';

  public groups: any[] = [];
  private expenses = [];

  public startMonthDate: string;
  public endMonthDate: string;

  public isLoading: boolean = false;
  public isLoadingExpenses: boolean = false;

  searchCtrl = new UntypedFormControl(null);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
    private expenseService: ExpenseService,
    private incomeService: IncomeService,
    private reportService: ReportService,
    private dataService: DataService,
    private eventService: EventService,
    private stateService: StateService,
    private helperService: HelperService,
    public dialog: MatDialog
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.id = +params['id'];
    });
  }

  ngOnInit() {
    this.setStartDate();
    this.updateDateRange();

    this.dataService.data.subscribe(data => {
      if (data.date) {
        this.date.setValue(data.date);
      }

      this.updateDateRange();
    });

    this.eventService
      .getUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (
          event.objectType === ObjectType.Expense &&
          event.type === EventType.Create
        ) {
          const expense: ExpenseModel = <ExpenseModel>event.object;
          if (expense.categoryId === this.id) {
            this.loadCategory();
          }
        } else if (
          event.objectType === ObjectType.Income &&
          event.type === EventType.Create
        ) {
          const expense: IncomeModel = <IncomeModel>event.object;
          if (expense.categoryId === this.id) {
            this.loadCategory();
          }
        }
      });

    this.searchCtrl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.expenses = [];
        if (
          this.categoryReport.category.categoryType === CategoryType.Expense
        ) {
          this.loadExpenses(this.date.value);
        } else {
          this.loadIncomes(this.date.value);
        }
      });
  }

  ngOnDestroy(): void {
    this.stateService.setValues(null, null);

    this.destroy$.next();
    this.destroy$.complete();
  }

  setStartDate(): void {
    const selectedDate: string = localStorage.getItem('selectedDate');

    let monthDate: Moment;
    if (selectedDate) {
      const obj = JSON.parse(selectedDate);
      if (moment.utc() < moment(obj.expired)) {
        monthDate = moment(obj.date, null, 'he');
      }
    }

    if (!monthDate || !monthDate.isValid()) {
      const date: Moment = this.helperService.getMonthStartDate();
      monthDate = moment(
        new Date(
          date.year(),
          date.month(),
          this.authenticationService.currentUserValue.user.startMonthDay
        ),
        null,
        'he'
      );
    }
    this.date = new UntypedFormControl(monthDate);
  }

  updateDateRange(): void {
    const monthDate: Moment = moment(
      new Date(
        this.date.value.year(),
        this.date.value.month(),
        this.authenticationService.currentUserValue.user.startMonthDay
      ),
      null,
      'he'
    );
    this.selectedMonth = monthDate.format('MMM YY');
    this.startMonthDate = monthDate.format('DD.MM.YYYY');
    this.endMonthDate = monthDate
      .add(1, 'months')
      .add(-1, 'days')
      .format('DD.MM.YYYY');

    this.loadCategory();
  }

  loadCategory(): void {
    const date = this.date.value;
    this.groups = [];
    this.expenses = [];
    this.isLoading = true;

    this.reportService
      .getCategory(this.id, null, date.year(), date.month() + 1)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.categoryReport = data;
        this.currentPeriodBudget = data.currentPeriodBudget;
        this.expensesSum = data.expensesSum;

        if (
          this.categoryReport.category.categoryType === CategoryType.Expense
        ) {
          this.loadExpenses(date);
        } else {
          this.loadIncomes(date);
        }

        this.eventService.send({
          type: EventType.Select,
          objectType: ObjectType.Category,
          object: {
            projectId: this.categoryReport.category.projectId,
            id: this.id,
          },
        });

        this.stateService.setValues(
          this.categoryReport.category.projectId,
          this.id
        );
      });
  }

  loadExpenses(date: any, offset: number = 0, size: number = 50): void {
    this.isLoadingExpenses = true;

    this.expenseService
      .list(
        50,
        offset,
        this.id,
        null,
        date.year(),
        date.month() + 1,
        null,
        null,
        null,
        this.searchCtrl.value
      )
      .pipe(
        finalize(() => (this.isLoadingExpenses = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.expenses = _.uniqBy(this.expenses.concat(data), 'id');

        const groups = _.groupBy(this.expenses, x => {
          const date = moment.utc(x.expenseDateTime, null, 'he').local();
          return date.format('dddd, D MMMM YYYY');
        });

        const result = [];
        _.forEach(groups, function (value, key) {
          result.push({
            date: key,
            expenses: _.sortBy(value, x => moment.utc(x.expenseDateTime)),
          });
        });

        this.groups = result;

        if (data.length === size) {
          // load next expenses
          this.loadExpenses(date, offset + size);
        }
      });
  }

  loadIncomes(date: any, offset: number = 0, size: number = 50): void {
    this.isLoadingExpenses = true;

    this.incomeService
      .list(
        50,
        offset,
        this.id,
        null,
        date.year(),
        date.month() + 1,
        this.searchCtrl.value
      )
      .pipe(
        finalize(() => (this.isLoadingExpenses = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.expenses = _.uniqBy(this.expenses.concat(data), 'id');

        const groups = _.groupBy(this.expenses, x => {
          const date = moment.utc(x.incomeDateTime, null, 'he').local();
          return date.format('dddd, D MMMM YYYY');
        });

        const result = [];
        _.forEach(groups, function (value, key) {
          result.push({ date: key, expenses: value });
        });

        this.groups = result;

        if (data.length === size) {
          // load next incomes
          this.loadIncomes(date, offset + size);
        }
      });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.date.setValue(normalizedMonth);
    datepicker.close();

    localStorage.setItem(
      'selectedDate',
      JSON.stringify({
        date: this.date.value,
        expired: moment.utc().add(1, 'day'),
      })
    );
    this.updateDateRange();
  }

  editItem(item: ExpenseModel | IncomeModel): void {
    item.projectId = this.categoryReport.category.projectId;
    const dialogRef: MatDialogRef<ExpenseWidgetComponent, any> =
      this.dialog.open(ExpenseWidgetComponent, {
        data: {
          categoryType: this.categoryReport.category.categoryType,
          item: { ...item },
        },
        panelClass: 'main-widget-panel',
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.loadCategory();
        }
      });
  }

  deleteItem(item: ExpenseModel | IncomeModel): void {
    this.dialog
      .open(DeleteDialogComponent, {
        panelClass: 'main-widget-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.value) {
          const date = this.date.value;

          if (
            this.categoryReport.category.categoryType === CategoryType.Expense
          ) {
            this.expenseService
              .delete(item.id, date.year(), date.month() + 1)
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.loadCategory();
              });
          } else {
            this.incomeService
              .delete(item.id, date.year(), date.month() + 1)
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.loadCategory();
              });
          }
        }
      });
  }

  edit(): void {
    const dialogRef: MatDialogRef<CategoryEditComponent, any> =
      this.dialog.open(CategoryEditComponent, {
        data: {
          category: this.categoryReport.category,
          startMonthDate: this.date.value,
        },
        panelClass: 'main-widget-panel',
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.updateDateRange();
        }
      });
  }

  delete(): void {
    this.dialog
      .open(DeleteDialogComponent, {
        panelClass: 'main-widget-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.value) {
          this.categoryService
            .delete(this.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.eventService.send({
                type: EventType.Delete,
                objectType: ObjectType.Category,
                object: { ...this.categoryReport.category },
              });
              this.router.navigate(['/home']);
            });
        }
      });
  }
}
