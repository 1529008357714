import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LoansModule } from '@app/loans/loans.module';
import { SavingsModule } from '@app/savings/savings.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from '../../_shared/shared.module';
import { MainLayoutComponent } from './main-layout.component';
import { MainLayoutRoutes } from './main-layout.routing';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(MainLayoutRoutes),
    MatIconModule,
    MatMenuModule,
    SavingsModule,
    LoansModule,
    PerfectScrollbarModule,
  ],
  declarations: [MainLayoutComponent],
})
export class MainLayoutModule {}
