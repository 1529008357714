import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  CategoryService,
  ProjectService,
  EventService,
  EventType,
  ObjectType,
  AuthenticationService,
  HelperService,
} from '@app/_services';
import {
  CategoryType,
  TypeOfCategoryCycle,
  ProjectModel,
  CategoryModel,
  ProjectType,
} from '@app/_common/models';
import { MyErrorStateMatcher } from '@app/_helpers';
import * as _ from 'lodash';
import { default as _rollupMoment, Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category-edit-panel',
  templateUrl: './category-edit-panel.component.html',
  styleUrls: ['./category-edit-panel.component.scss'],
})
export class CategoryEditPanelComponent implements OnInit {
  _category: CategoryModel;
  get category(): CategoryModel {
    return this._category;
  }

  @Input('category')
  set category(value: CategoryModel) {
    this._category = value;
    this.initCategory();
  }

  @Input() startMonthDate: Moment;
  @Output() update: EventEmitter<CategoryModel> =
    new EventEmitter<CategoryModel>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  categoryForm: UntypedFormGroup;

  categoryTypes: { id: number; value: string }[] = [
    {
      id: CategoryType.Expense,
      value: 'הוצאה',
    },
    {
      id: CategoryType.Income,
      value: 'הכנסה',
    },
  ];

  typeOfCategoryCycle: { id: number; value: string }[] = [
    {
      id: TypeOfCategoryCycle.Monthly,
      value: 'חודשי',
    },
    {
      id: TypeOfCategoryCycle.Weekly,
      value: 'שבועי', // "Weekly"
    },
    {
      id: TypeOfCategoryCycle.Business1,
      value: 'עסק 1', // "Business1"
    },
    {
      id: TypeOfCategoryCycle.Business2,
      value: 'עסק 2', // "Business2"
    },
    {
      id: TypeOfCategoryCycle.OneTime,
      value: 'רק החודש', // "OneTime"
    },
    //{
    //  "id": TypeOfCategoryCycle.AdHoc,
    //  "value": "AdHoc"
    //}
  ];

  bussinesTypeOfCategoryCycle: { id: number; value: string }[] = [
    {
      id: TypeOfCategoryCycle.Monthly,
      value: 'חודשי',
    },
    {
      id: TypeOfCategoryCycle.Weekly,
      value: 'שבועי', // "Weekly"
    },
    {
      id: TypeOfCategoryCycle.OneTime,
      value: 'רק החודש', // "OneTime"
    },
  ];

  projects: ProjectModel[] = [];
  currentProject: ProjectModel;

  matcher = new MyErrorStateMatcher();

  private subs: Subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
    private projectService: ProjectService,
    private eventService: EventService,
    private toastr: ToastrService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.initCategory();

    this.subs.add(
      this.projectService.list(null).subscribe(data => {
        this.projects = data.filter(x => x.type != ProjectType.AdHoc); // don't support AdHoc on phase 1
        if (this.projects.length > 0) {
          this.currentProject = this.projects.find(
            x => x.id === (this.category || { projectId: 0 }).projectId
          );
          if (!this.currentProject) {
            this.currentProject = this.projects[0];
            this.categoryForm.controls['projectId'].setValue(
              this.currentProject.id
            );
          }
        }
      })
    );
  }

  initCategory() {
    if (this.category) {
      this.categoryForm = this.formBuilder.group({
        name: [this.category.name, Validators.required],
        budget: [this.category.budget, Validators.required],
        notes: [this.category.notes],
        categoryType: [this.category.categoryType, Validators.required],
        type: [this.category.type, Validators.required],
        projectId: [this.category.projectId, Validators.required],
      });

      this.currentProject = this.projects.find(
        x => x.id === this.category.projectId
      );
    } else {
      this.categoryForm = this.formBuilder.group({
        name: ['', Validators.required],
        budget: ['', Validators.required],
        notes: [''],
        categoryType: [this.categoryTypes[0].id, Validators.required],
        type: [this.typeOfCategoryCycle[0].id, Validators.required],
        projectId: ['', Validators.required],
      });

      if (this.projects.length > 0) {
        this.currentProject = this.projects[0];
        this.categoryForm.controls['projectId'].setValue(
          this.currentProject.id
        );
      }
    }
  }

  updateProject(event: any): void {
    this.currentProject = this.projects.find(x => x.id === event.value);
    if (
      this.currentProject.isBusiness &&
      this.bussinesTypeOfCategoryCycle.some(
        x => x.id === this.categoryForm.value.type
      ) === false
    ) {
      this.categoryForm.controls['type'].setValue(
        this.bussinesTypeOfCategoryCycle[0].id
      );
    }
  }

  resetForm(event: any): void {
    const oldValue = this.categoryForm.value;
    oldValue.name = '';
    oldValue.budget = '';

    event.target.reset();
    this.categoryForm.reset(oldValue);
  }

  submit(event: any): void {
    if (this.categoryForm.invalid) {
      return;
    }

    if (this.currentProject.type === ProjectType.AdHoc) {
      this.categoryForm.value.type == TypeOfCategoryCycle.AdHoc;
    }

    if (this.category) {
      let category = Object.assign(this.category, this.categoryForm.value);
      category.budgetDate = new Date(
        Date.UTC(
          this.startMonthDate.year(),
          this.startMonthDate.month(),
          this.authenticationService.currentUserValue.user.startMonthDay,
          0,
          0,
          0,
          0
        )
      );

      const endMonthDate = this.helperService
        .getMonthStartDate()
        .add(1, 'months');
      if (category.budgetDate < endMonthDate) {
        category.budgetDate = null;
      }

      this.subs.add(
        this.categoryService.update(category).subscribe(
          data => {
            this.resetForm(event);
            this.eventService.send({
              type: EventType.Update,
              objectType: ObjectType.Category,
              object: { ...category },
            });
            this.update.emit({ ...category });
            this.toastr.success('עודכן');
          },
          err => {
            this.toastr.error('Error');
            console.warn(err);
          }
        )
      );
    } else {
      let category: CategoryModel = this.categoryForm.value;
      this.subs.add(
        this.categoryService.save(category).subscribe(
          data => {
            this.resetForm(event);
            this.eventService.send({
              type: EventType.Create,
              objectType: ObjectType.Category,
              object: data,
            });
            this.update.emit(data);
            this.toastr.success('עודכן');
          },
          err => {
            this.toastr.error('Error');
            console.warn(err);
          }
        )
      );
    }
  }

  cancelHandler(event: any): void {
    event.preventDefault();
    this.cancel.emit(true);
  }
}
