import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { BalanceColorDirective } from '@app/_common/directives/balanceColor';
import { NumbersOnlyDirective } from '@app/_common/directives/numbersOnly';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryEditPanelComponent } from './category-edit-panel/category-edit-panel.component';
import { CategoryEditComponent } from './category-edit/category-edit.component';
import { CreateDefaultCategoriesComponent } from './create-default-categories/create-default-categories.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ExpenseWidgetComponent } from './expense-widget/expense-widget.component';
import { SuccessUpdatedComponent } from './success-updated/success-updated.component';

@NgModule({
  declarations: [
    ExpenseWidgetComponent,
    CategoryEditPanelComponent,
    CategoryEditComponent,
    NumbersOnlyDirective,
    BalanceColorDirective,
    DeleteDialogComponent,
    SuccessUpdatedComponent,
    CreateDefaultCategoriesComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTabsModule,
    MatIconModule,
    MatDialogModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ExpenseWidgetComponent,
    CategoryEditPanelComponent,
    CategoryEditComponent,
    NumbersOnlyDirective,
    BalanceColorDirective,
  ],
})
export class SharedModule {}
