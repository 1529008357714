import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  CategoryReportModel,
  TotalReportModel,
  ReportFileModel,
} from '../_common/models';

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private http: HttpClient) {}

  get(
    all: boolean,
    userId: number,
    year: number,
    month: number,
    projectId: number
  ) {
    return this.http.get<CategoryReportModel[]>(
      `api/reports?all=${all}&userId=${userId}&year=${year}&month=${month}&project=${projectId}`
    );
  }

  getTotal(
    userId: number,
    type: any,
    expensesType: any,
    year: string,
    month: string,
    projectId: any
  ): any {
    return this.http.get<TotalReportModel>(
      `api/reports/total?userId=${userId}&type=${type}&expensesType=${expensesType}&year=${year}&month=${month}&project=${projectId}`
    );
  }

  getCategory(
    categoryId: number,
    userId: number,
    year: string,
    month: string
  ): any {
    return this.http.get<CategoryReportModel>(
      `api/reports/${categoryId}?userId=${userId}&year=${year}&month=${month}`
    );
  }

  generateMonthReport(
    userId: number,
    year: number,
    month: number,
    projectId: number
  ) {
    return this.http.get<ReportFileModel>(
      `api/reports/generateMonthReport?userId=${userId}&year=${year}&month=${month}&project=${projectId}`
    );
  }

  getCategoriesYearlyReport(categoryIds): any {
    return this.http.post<{ months: { date: string; amount: number }[] }>(
      `api/reports/yearly/categories`,
      categoryIds
    );
  }

  getYearsReportByCategories(model: {
    startYear: number;
    endYear: number;
    projectId: number;
  }): any {
    return this.http.post<{ months: { date: string; amount: number }[] }>(
      `api/reports/years/categories`,
      model
    );
  }

  generateYearsReportByCategories(model: {
    startYear: number;
    endYear: number;
    projectId: number;
  }): any {
    return this.http.post<{ months: { date: string; amount: number }[] }>(
      `api/reports/years/categories/generate`,
      model
    );
  }
}
