import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  MatCheckboxChange,
  TransitionCheckState,
} from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MY_FORMATS_DATE } from '@app/_common/dateFormats';
import {
  PaymentModel,
  SavingModel,
  SavingPaymentType,
} from '@app/_common/models/saving';
import { MyErrorStateMatcher } from '@app/_helpers';
import {
  EventService,
  EventType,
  ObjectType,
  PaymentService,
  SavingService,
} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SavingWidgetComponent } from '../saving-widget/saving-widget.component';

@Component({
  selector: 'app-payment-widget',
  templateUrl: './payment-widget.component.html',
  styleUrls: ['./payment-widget.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DATE },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class PaymentWidgetComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  paymentForm: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();

  savings: SavingModel[] = [];

  depositTypes: { id: number; value: string }[] = [
    {
      id: 0,
      value: 'הפקדה',
    },
    {
      id: 1,
      value: 'הפקדה חודשית קבועה',
    },
    {
      id: 2,
      value: 'עדכון תשואה (שקלים)',
    },
  ];

  loading: boolean = false;

  tab: string = 'depositTab';
  item: PaymentModel;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    @Optional() public dialogRef: MatDialogRef<PaymentWidgetComponent>,
    private paymentService: PaymentService,
    private savingService: SavingService,
    private eventService: EventService,
    private translate: TranslateService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PaymentModel
  ) {
    if (data) {
      this.item = data;
      if (this.item.type === 1) {
        this.tab = 'withdrawalTab';
      }
    }
  }

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group({
      amount: [0, Validators.required],
      savingId: [null, Validators.required],
      description: [''],
      depositType: [this.depositTypes[0].id, Validators.required], // for deposit only
      type: [0, Validators.required],
      dateTime: [moment(), Validators.required],
      isFullAmount: [false],
    });

    this.eventService
      .getUpdate()
      .pipe(
        filter(
          e =>
            e.objectType === ObjectType.Saving ||
            e.objectType === ObjectType.SavingPayment
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(event => {
        if (event.type === EventType.Select) {
          this.paymentForm.controls['savingId'].setValue(event.object.savingId);
        }
        if (
          event.type === EventType.Create ||
          event.type === EventType.Update ||
          event.type === EventType.Delete
        ) {
          this.loadSavings();
        }
      });

    if (this.item) {
      this.paymentForm.reset(this.item);

      if (this.item.type == SavingPaymentType.Withdrawal) {
        this.paymentForm.controls['depositType'].removeValidators([
          Validators.required,
        ]);
      }

      this.paymentForm.controls['dateTime'].setValue(
        moment.utc(this.item.dateTime).local()
      );
    }

    this.loadSavings();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadSavings(): void {
    this.savingService
      .list()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.savings = data;
        if (
          !this.savings.some(
            x => x.id === this.paymentForm.controls['savingId'].value
          )
        ) {
          this.paymentForm.controls['savingId'].setValue(null);
        }
      });
  }

  reset(tab: string): void {
    this.tab = tab;
    if (this.tab === 'depositTab') {
      this.paymentForm.controls['type'].setValue(0);
    } else {
      this.paymentForm.controls['type'].setValue(1);
    }
  }

  onSubmit(event: any): void {
    if (this.paymentForm.invalid) {
      return;
    }

    const model: PaymentModel = this.paymentForm.value;
    if (model.amount <= 0) {
      return;
    }

    if (this.item) {
      const payment = Object.assign(this.item, model);

      this.paymentService
        .update(payment)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.eventService.send({
              type: EventType.Update,
              objectType: ObjectType.SavingPayment,
              object: { ...data },
            });
            this.toastr.success('עודכן');

            if (this.dialogRef) {
              this.dialogRef.close();
            } else {
              this.resetForm(event);
            }
          },
          err => this.toastr.error('Error')
        );
    } else {
      if (model.type == SavingPaymentType.Withdrawal) {
        if (model.amount > this.getFullAmount()) {
          this.toastr.error(
            this.translate.instant('paymentWidget.notEnoughBalanceInSavings')
          );
          return;
        }
      }

      this.paymentService
        .save(model)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.eventService.send({
              type: EventType.Create,
              objectType: ObjectType.SavingPayment,
              object: { ...data },
            });
            this.toastr.success('עודכן');

            if (this.dialogRef) {
              this.dialogRef.close();
            } else {
              this.resetForm(event);
            }
          },
          err => this.toastr.error('Error')
        );
    }
  }

  cancel(event): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  resetForm(event: any): void {
    const oldValue = this.paymentForm.value;
    oldValue.amount = 0;
    oldValue.description = '';
    oldValue.date = moment();
    oldValue.isFullAmount = false;
    oldValue.depositType = 0;

    event.target.reset();
    this.paymentForm.reset(oldValue);
  }

  choseSaving(): void {
    if (this.paymentForm.controls['isFullAmount'].value) {
      this.setFullAmount();
    }
  }

  fillFullAmount(event: MatCheckboxChange): void {
    if (event.checked) {
      this.setFullAmount();
    }
  }

  setFullAmount(): void {
    this.paymentForm.controls['amount'].setValue(this.getFullAmount());
  }

  getFullAmount(): number {
    const saving = this.savings.find(
      x => x.id == this.paymentForm.controls['savingId'].value
    );
    if (saving) {
      let amount = saving.totalDepositsAmount - saving.totalWithdrawalAmount;
      if (amount < 0) {
        amount = 0;
      }
      return amount;
    }
  }

  createSaving(): void {
    this.dialog.open(SavingWidgetComponent, {
      data: null,
      panelClass: 'main-widget-panel',
    });
  }
}
