import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoanModel } from '../_common/models/loan';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoanService {
  constructor(private http: HttpClient) { }

  get(id: number) {
    return this.http.get<LoanModel>(`api/v1/loan/${id}`);
  }

  save(loan: LoanModel) {
    return this.http.post<LoanModel>(`api/v1/loan`, loan);
  }

  update(loan: LoanModel) {
    return this.http.put<LoanModel>(`api/v1/loan`, loan);
  }

  delete(id: number) {
    return this.http.delete(`api/v1/loan?id=${id}`);
  }

  list(loanId?: number, offset: number = 0, size?: number, userId?: number, search?: string, withSummary: boolean = true, year?: number, month?: number): Observable<LoanModel[]> {
    let query = `offset=${offset}&withSummary=${withSummary}`;

    if (size) {
      query += `&size=${size}`;
    }

    if (userId) {
      query += `&userId=${userId}`;
    }

    if (search) {
      query += `&search=${search}`;
    }

    if (year) {
      query += `&year=${year}`;
    }

    if (month) {
      query += `&month=${month}`;
    }

    if (loanId) {
      query += `&loanId=${loanId}`;
    }

    return this.http.get<LoanModel[]>(`api/v1/loan/list?${query}`);
  }
}
