import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoanComponent } from './loan/loan.component';
import { LoansComponent } from './loans/loans.component';

const routes: Routes = [
  {
    path: '',
    component: LoansComponent
  },
  {
    path: ':id',
    component: LoanComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoansRoutingModule { }
