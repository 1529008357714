import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AuthenticationService,
  EventService,
  EventType,
  LoanService,
  ObjectType,
} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { LoanWidgetComponent } from '../loan-widget/loan-widget.component';
import * as _ from 'lodash';
import { LoanModel } from '@app/_common/models/loan/loanModel';
import { DeleteDialogComponent } from '@app/_shared/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss'],
})
export class LoansComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  totalLoanAmount: number = 0;

  rows: LoanModel[] = [];
  public isLoading: boolean = false;

  constructor(
    private loanService: LoanService,
    private authenticationService: AuthenticationService,
    private eventService: EventService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.load();

    this.eventService
      .getUpdate()
      .pipe(
        filter(
          e =>
            e.objectType === ObjectType.Loan ||
            e.objectType === ObjectType.LoanPayment
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(event => {
        if (
          event.type === EventType.Create ||
          event.type === EventType.Update ||
          event.type === EventType.Delete
        ) {
          this.load();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  load(): void {
    this.isLoading = true;
    this.loanService
      .list()
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.rows = data;
        this.totalLoanAmount = _.sumBy(data, 'totalLoanLeftAmount');
      });
  }

  editItem(event: MouseEvent, item: any): void {
    event.stopPropagation();
    event.preventDefault();

    const dialogRef: MatDialogRef<LoanWidgetComponent, any> = this.dialog.open(
      LoanWidgetComponent,
      {
        data: {
          ...item,
        },
        panelClass: 'main-widget-panel',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.eventService.send({
            type: EventType.Update,
            objectType: ObjectType.Loan,
            object: { ...result },
          });
        }
      });
  }

  deleteItem(event: MouseEvent, item: any): void {
    event.stopPropagation();
    event.preventDefault();

    this.dialog
      .open(DeleteDialogComponent, {
        panelClass: 'main-widget-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.value) {
          this.loanService
            .delete(item.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              this.eventService.send({
                type: EventType.Delete,
                objectType: ObjectType.Loan,
                object: item,
              });
            });
        }
      });
  }
}
