import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TypeOfPay } from '@app/_common/models';

@Injectable({ providedIn: 'root' })
export class SettingsService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get<{ type: TypeOfPay, name: string }[]>(`api/settings/paymentTypes`);
  }

  update(model: any) {
    return this.http.put(`api/settings/paymentType`, model);
  }
}
