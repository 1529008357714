export class ExpenseModel {
  id: number;
  createdDate: string;
  userId: number;
  categoryId: number;
  amount: number;
  description: string;
  spenderName: string;
  type: TypeOfPay;
  expenseDateTime: string;
  ADID: string;
  typeOfExpense: ExpensesType;
  countOfTimes: number;
  totalAmount: number;
  isEditable: boolean;
  projectId: number;
}

export enum TypeOfPay {
  CreditCard = 0,
  Cash = 1,
  Check = 2,
  BankTransfer = 3,
  Refundable = 4,
  CreditCard2 = 5,
  Other1 = 6,
  Other2 = 7,
  CreditCard3 = 8,
  CreditCard4 = 9,
  Other3 = 10,
  Other4 = 11
}

export enum ExpensesType {
  Expense = 0,
  Payments = 1,
  DirectDebit = 2
}
