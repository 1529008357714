import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class DeviceGuard implements CanActivate, CanActivateChild  {
  private readonly googlePlayUrl: string = 'https://play.google.com/store/apps/details?id=com.expenses.android';
  private readonly appStoreUrl: string = 'https://apps.apple.com/us/app/%D7%94%D7%97%D7%99%D7%99%D7%9D-%D7%91%D7%A4%D7%9C%D7%95%D7%A1/id1397736105';

  private readonly appUrl: string = 'expense://home';

  constructor() { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.detectClientAgent();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.detectClientAgent();
  }

  private detectClientAgent(): boolean {
    const operatingSystem = DeviceGuard.getMobileOperatingSystem();

    if (operatingSystem !== OSType.Unknown) {
      this.openPage(operatingSystem === OSType.Android ? this.googlePlayUrl : this.appStoreUrl, this.appUrl);
      return false;
    }

    return true;
  }

  private static getMobileOperatingSystem(): OSType {
    if (window.location.href.indexOf('/policies') !== -1) {
      return OSType.Unknown;
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return OSType.Android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return OSType.Apple;
    }

    return OSType.Unknown;
  }

  private openPage(webUrl, appUrl): void {
    let loadedAt = +new Date;
    let interval = setInterval(function () {
      let res = +new Date - loadedAt;
      if (res > 1000) {
        clearInterval(interval);
        window.location.href = webUrl;
      }
    }, 25);

    // Try launching the app using URL schemes
    window.location.href = appUrl;
  }
}

export enum OSType {
  Apple,
  Android,
  Unknown
}
