import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_common/guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { CategoryComponent } from './category/category.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { DeviceGuard } from './_common/guards/device.guard';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';
import { StatsComponent } from './stats/stats.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { YearsReportsComponent } from './years-reports/years-reports.component';
import { SavingsModule } from './savings/savings.module';
import { LoansModule } from './loans/loans.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard, DeviceGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'category/:projectId/:id',
        component: CategoryComponent
      },
      {
        path: 'expenses',
        component: ExpensesComponent
      },
      {
        path: 'stats',
        component: StatsComponent
      },
      {
        path: 'bankAccount',
        component: BankAccountComponent
      },
      {
        path: 'yearsReport',
        component: YearsReportsComponent
      },
      {
        path: 'savings',
        loadChildren: () => SavingsModule
      },
      {
        path: 'loans',
        loadChildren: () => LoansModule
      }
    ]
  },
  { path: 'login', component: LoginComponent, canActivate: [DeviceGuard], canActivateChild: [DeviceGuard] },
  { path: 'term', component: TermOfUseComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
