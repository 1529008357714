import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService, UserService } from '../_services';
import { Subscription } from 'rxjs';
import { MyErrorStateMatcher } from '@app/_helpers';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  loading = false;
  returnUrl: string;
  error: any;

  forgotPasswordForm: UntypedFormGroup;
  isShowForgotPassword: boolean = false;

  matcher = new MyErrorStateMatcher();

  private subs: Subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      IsAcceptedTermsOfUse: [false, Validators.pattern('true')],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.subs.add(
      this.authenticationService
        .login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          () => {
            this.userService.agreeTermsOfUse().subscribe(
              () => {
                this.router.navigate([this.returnUrl]);
              },
              error => {
                this.error = error;
                this.toastr.error(this.error);
                this.loading = false;
                this.authenticationService.logout();
              }
            );
          },
          error => {
            if (error === 'Bad Request') {
              this.error = this.translate.instant('login.loginFailed');
            } else if (error === 'Forbidden') {
              this.error = this.translate.instant('login.forbidden');
            } else {
              this.error = error.message;
            }
            if (this.error) {
              this.toastr.error(this.error);
            }

            this.loading = false;
          }
        )
    );
  }

  showForgotPassword(): void {
    this.isShowForgotPassword = true;
    this.loginForm.controls['password'].setValue('');
  }

  onForgotPasswordSubmit(): void {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.subs.add(
      this.userService
        .forgotPassword({
          email: this.forgotPasswordForm.controls['username'].value,
        })
        .subscribe(
          () => {
            this.toastr.success('עודכן');
            this.loading = false;
          },
          error => {
            this.toastr.error('Error');
            this.loading = false;
          }
        )
    );
  }

  back(event): void {
    event.stopPropagation();
    event.preventDefault();

    this.isShowForgotPassword = false;
  }
}
