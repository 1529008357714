import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './_services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();

  constructor(
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private bnIdle: BnNgIdleService,
    private authenticationService: AuthenticationService
  ) {
    translate.setDefaultLang('he');
  }

  ngOnInit(): void {
    this.subs.add(
      this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          if (this.authenticationService.currentUserValue) {
            console.log('session expired');
            this.dialog.closeAll();
            this.authenticationService.logout();
            this.router.navigate(['/login']);
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
