import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoanPaymentModel } from '../_common/models/loan';

@Injectable({ providedIn: 'root' })
export class LoanPaymentService {
  constructor(private http: HttpClient) { }

  save(model: LoanPaymentModel) {
    return this.http.post<LoanPaymentModel>(`api/v1/loanPayment`, model);
  }

  update(model: LoanPaymentModel) {
    return this.http.put<LoanPaymentModel>(`api/v1/loanPayment`, model);
  }

  delete(id: number, year: number, month: number) {
    return this.http.delete<any>(`api/v1/loanPayment?id=${id}&year=${year}&month=${month}`);
  }

  list(offset: number, size: number, loanId: number, year: string, month: string, userId?: number,) {
    let query = `offset=${offset}&loanId=${loanId}&year=${year}&month=${month}`;

    if (size) {
      query += `size=${size}`;
    }

    if (userId) {
      query += `size=${userId}`;
    }

    return this.http.get<LoanPaymentModel[]>(`api/v1/loanPayment/list?${query}`);
  }
}
