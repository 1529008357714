import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IncomeModel } from '../_common/models';

@Injectable({ providedIn: 'root' })
export class IncomeService {
  constructor(private http: HttpClient) { }

  save(model: IncomeModel) {
    return this.http.post<IncomeModel>(`api/incomes`, model);
  }

  update(model: IncomeModel) {
    return this.http.put<IncomeModel>(`api/incomes`, model);
  }

  delete(id: number, year: number, month: number) {
    return this.http.delete<any>(`api/incomes?id=${id}&year=${year}&month=${month}`);
  }

  list(size: number, offset: number, categoryId: number, userId: number, year: string, month: string, search: string = null) {
    let url = `api/incomes/list/${size}/${offset}?categoryId=${categoryId}&userId=${userId}&year=${year}&month=${month}`;

    if (search) {
      url += `&search=${search}`;
    }

    return this.http.get<IncomeModel[]>(url);
  }
}
