import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MainLayoutService {
  private subject = new Subject<any>();

  constructor() { }

  getUpdate(): Observable<{ eventType: ScrollEventType }> {
    return this.subject.asObservable();
  }

  send(event: {eventType: ScrollEventType }): void {
    this.subject.next(event);
  }
}

export enum ScrollEventType {
  ToTop
}
