import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoansComponent } from './loans/loans.component';
import { LoanComponent } from './loan/loan.component';
import { LoanWidgetComponent } from './loan-widget/loan-widget.component';
import { LoansRoutingModule } from './loans-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@app/_shared/shared.module';
import { LoanPaymentWidgetComponent } from './loan-payment-widget/loan-payment-widget.component';

@NgModule({
    declarations: [
        LoansComponent,
        LoanComponent,
        LoanWidgetComponent,
        LoanPaymentWidgetComponent
    ],
    imports: [
        CommonModule,
        LoansRoutingModule,
        TranslateModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        SharedModule
    ],
    exports: [
        LoanPaymentWidgetComponent
    ]
})
export class LoansModule { }
