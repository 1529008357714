import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-updated',
  templateUrl: './success-updated.component.html',
  styleUrls: ['./success-updated.component.scss'],
})
export class SuccessUpdatedComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SuccessUpdatedComponent>) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
  }
}
