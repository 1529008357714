import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  MatCheckboxChange,
  TransitionCheckState,
} from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MY_FORMATS_DATE } from '@app/_common/dateFormats';
import {
  LoanPaymentModel,
  LoanModel,
  LoanPaymentType,
} from '@app/_common/models/loan';
import { MyErrorStateMatcher } from '@app/_helpers';
import {
  EventService,
  EventType,
  ObjectType,
  PaymentService,
  LoanService,
  LoanPaymentService,
} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoanWidgetComponent } from '../loan-widget/loan-widget.component';

@Component({
  selector: 'app-loan-payment-widget',
  templateUrl: './loan-payment-widget.component.html',
  styleUrls: ['./loan-payment-widget.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DATE },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class LoanPaymentWidgetComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  paymentForm: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();

  loans: LoanModel[] = [];

  types: { id: number; value: string }[] = [
    {
      id: 0,
      value: 'תשלום חד פעמי',
    },
    {
      id: 1,
      value: 'תשלום חודשי קבוע',
    },
  ];

  loading: boolean = false;
  item: LoanPaymentModel;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    @Optional() public dialogRef: MatDialogRef<LoanPaymentWidgetComponent>,
    private loanPaymentService: LoanPaymentService,
    private loanService: LoanService,
    private eventService: EventService,
    private translate: TranslateService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: LoanPaymentModel
  ) {
    if (data) {
      this.item = data;
    }
  }

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group({
      amount: [0, Validators.required],
      loanId: [null, Validators.required],
      description: [''],
      type: [this.types[0].id, Validators.required],
      dateTime: [moment(), Validators.required],
    });

    this.eventService
      .getUpdate()
      .pipe(
        filter(
          e =>
            e.objectType === ObjectType.Loan ||
            e.objectType === ObjectType.LoanPayment
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(event => {
        if (event.type === EventType.Select) {
          this.paymentForm.controls['loanId'].setValue(event.object.loanId);
        }
        if (
          event.type === EventType.Create ||
          event.type === EventType.Update ||
          event.type === EventType.Delete
        ) {
          this.loadLoans();
        }
      });

    if (this.item) {
      this.paymentForm.reset(this.item);
      this.paymentForm.controls['dateTime'].setValue(
        moment.utc(this.item.dateTime).local()
      );
    }

    this.loadLoans();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadLoans(): void {
    this.loanService
      .list()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.loans = data;
        if (
          !this.loans.some(
            x => x.id === this.paymentForm.controls['loanId'].value
          )
        ) {
          this.paymentForm.controls['loanId'].setValue(null);
        }
      });
  }

  onSubmit(event: any): void {
    if (this.paymentForm.invalid) {
      return;
    }

    const model: LoanPaymentModel = this.paymentForm.value;
    if (model.amount <= 0) {
      return;
    }

    if (this.item) {
      const payment = Object.assign(this.item, model);

      this.loanPaymentService
        .update(payment)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.eventService.send({
              type: EventType.Update,
              objectType: ObjectType.LoanPayment,
              object: { ...data },
            });
            this.toastr.success('עודכן');

            if (this.dialogRef) {
              this.dialogRef.close();
            } else {
              this.resetForm(event);
            }
          },
          err => this.toastr.error('Error')
        );
    } else {
      this.loanPaymentService
        .save(model)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.eventService.send({
              type: EventType.Create,
              objectType: ObjectType.LoanPayment,
              object: { ...data },
            });
            this.toastr.success('עודכן');

            if (this.dialogRef) {
              this.dialogRef.close();
            } else {
              this.resetForm(event);
            }
          },
          err => this.toastr.error('Error')
        );
    }
  }

  cancel(event): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  resetForm(event: any): void {
    const oldValue = this.paymentForm.value;
    oldValue.amount = 0;
    oldValue.description = '';
    oldValue.date = moment();
    oldValue.isFullAmount = false;
    oldValue.depositType = 0;

    event.target.reset();
    this.paymentForm.reset(oldValue);
  }

  createLoan(): void {
    this.dialog.open(LoanWidgetComponent, {
      data: null,
      panelClass: 'main-widget-panel',
    });
  }
}
