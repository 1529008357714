import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  ProjectModel,
  ProjectType,
  CategoryType,
  TypeOfPay,
  ExpensesType,
} from '@app/_common/models';
import {
  AuthenticationService,
  ExpenseService,
  ProjectService,
  ReportService,
  HelperService,
  EventService,
  EventType,
  ObjectType,
  SettingsService,
} from '@app/_services';
import * as _ from 'lodash';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { Subject } from 'rxjs';
import { ExpenseWidgetComponent } from '@app/_shared/expense-widget/expense-widget.component';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  takeUntil,
} from 'rxjs/operators';
import { DeleteDialogComponent } from '@app/_shared/delete-dialog/delete-dialog.component';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class ExpensesComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  projects: ProjectModel[] = [];
  currentProject: ProjectModel;

  currentPeriodBudget: number = 0;
  expensesSum: number = 0;

  public date: UntypedFormControl;
  public selectedMonth: string = '';

  public groups: any = [];
  private expenses = [];

  types: {
    id: string;
    value: string;
    enumValue: any;
    isEditable: boolean;
    order: number;
  }[] = [
    {
      id: 'CreditCard',
      value: 'כרטיס אשראי 1',
      enumValue: TypeOfPay.CreditCard,
      isEditable: true,
      order: 0,
    },
    {
      id: 'CreditCard2',
      value: 'כרטיס אשראי 2',
      enumValue: TypeOfPay.CreditCard2,
      isEditable: true,
      order: 1,
    },
    {
      id: 'CreditCard3',
      value: 'כרטיס אשראי 3',
      enumValue: TypeOfPay.CreditCard3,
      isEditable: true,
      order: 2,
    },
    {
      id: 'CreditCard4',
      value: 'כרטיס אשראי 4',
      enumValue: TypeOfPay.CreditCard4,
      isEditable: true,
      order: 3,
    },
    {
      id: 'Cash',
      value: 'מזומן',
      enumValue: TypeOfPay.Cash,
      isEditable: false,
      order: 4,
    },
    {
      id: 'Other1',
      value: 'אחר',
      enumValue: TypeOfPay.Other1,
      isEditable: true,
      order: 5,
    },
    {
      id: 'Other2',
      value: 'אחר 2',
      enumValue: TypeOfPay.Other2,
      isEditable: true,
      order: 6,
    },
    {
      id: 'Other3',
      value: 'אחר 3',
      enumValue: TypeOfPay.Other3,
      isEditable: true,
      order: 7,
    },
    {
      id: 'Other4',
      value: 'אחר 4',
      enumValue: TypeOfPay.Other4,
      isEditable: true,
      order: 8,
    },
    {
      id: 'Refundable',
      value: 'יתקבל החזר',
      enumValue: TypeOfPay.Refundable,
      isEditable: false,
      order: 100,
    },
    {
      id: 'BankTransfer',
      value: 'העברה',
      enumValue: TypeOfPay.BankTransfer,
      isEditable: false,
      order: 101,
    },
    {
      id: 'Check',
      value: 'שיק',
      enumValue: TypeOfPay.Check,
      isEditable: false,
      order: 102,
    },
  ];

  allTypes: {
    id: string;
    value: string;
    enumValue: any;
    isEditable: boolean;
  }[] = [];
  selectedTypeOfExpense: any = null;

  public startMonthDate: string;
  public endMonthDate: string;

  public isLoading: boolean = false;
  public isLoadingExpenses: boolean = false;

  searchCtrl = new UntypedFormControl(null);

  constructor(
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private expenseService: ExpenseService,
    private reportService: ReportService,
    private eventService: EventService,
    private helperService: HelperService,
    private settingsService: SettingsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setStartDate();
    this.updateDateRange();

    this.eventService
      .getUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (
          event.objectType === ObjectType.Expense &&
          event.type === EventType.Create
        ) {
          this.fetchProject();
        } else if (
          event.objectType === ObjectType.Income &&
          event.type === EventType.Create
        ) {
          this.fetchProject();
        }
      });

    this.fetchProjects();

    this.searchCtrl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.expenses = [];
        this.loadExpenses(this.date.value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setStartDate(): void {
    const selectedDate = localStorage.getItem('selectedDate');

    let monthDate;
    if (selectedDate) {
      const obj = JSON.parse(selectedDate);
      if (moment.utc() < moment(obj.expired)) {
        monthDate = moment(obj.date, null, 'he');
      }
    }

    if (!monthDate || !monthDate.isValid()) {
      const date = this.helperService.getMonthStartDate();
      monthDate = moment(
        new Date(
          date.year(),
          date.month(),
          this.authenticationService.currentUserValue.user.startMonthDay
        ),
        null,
        'he'
      );
    }
    this.date = new UntypedFormControl(monthDate);
  }

  updateDateRange(): void {
    const monthDate = moment(
      new Date(
        this.date.value.year(),
        this.date.value.month(),
        this.authenticationService.currentUserValue.user.startMonthDay
      ),
      null,
      'he'
    );
    this.selectedMonth = monthDate.format('MMM YY');
    this.startMonthDate = monthDate.format('DD.MM.YYYY');
    this.endMonthDate = monthDate
      .add(1, 'months')
      .add(-1, 'days')
      .format('DD.MM.YYYY');

    this.fetchProject();
  }

  loadExpenses(date: any, offset: number = 0, size: number = 50): void {
    this.isLoadingExpenses = true;

    let type = this.selectedTypeOfExpense;
    let typeOfExpense = null;

    if (type === 'Payments') {
      type = TypeOfPay.CreditCard;
      typeOfExpense = ExpensesType.Payments;
    } else if (type === 'DirectDebit') {
      type = TypeOfPay.CreditCard;
      typeOfExpense = ExpensesType.DirectDebit;
    }

    this.expenseService
      .list(
        50,
        offset,
        null,
        null,
        date.year(),
        date.month() + 1,
        this.currentProject.id,
        type,
        typeOfExpense,
        this.searchCtrl.value
      )
      .pipe(
        finalize(() => (this.isLoadingExpenses = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.expenses = _.uniqBy(this.expenses.concat(data), 'id');

        const groups = _.groupBy(this.expenses, x => {
          const date = moment.utc(x.expenseDateTime, null, 'he').local();
          return date.format('dddd, D MMMM YYYY');
        });

        const result = [];
        _.forEach(groups, function (value, key) {
          result.push({
            date: key,
            expenses: _.sortBy(value, x => moment.utc(x.expenseDateTime)),
          });
        });

        this.groups = result;

        if (data.length === size) {
          // load next expenses
          this.loadExpenses(date, offset + size);
        }
      });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.date.setValue(normalizedMonth);
    datepicker.close();

    localStorage.setItem(
      'selectedDate',
      JSON.stringify({
        date: this.date.value,
        expired: moment.utc().add(1, 'day'),
      })
    );
    this.updateDateRange();
  }

  fetchProjects(): void {
    this.projectService
      .list(null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.projects = data.filter(x => x.type != ProjectType.AdHoc); // don't support AdHoc on phase 1
        if (this.projects.length > 0) {
          const currentProjectId: number = +(
            localStorage.getItem('currentProject') || '0'
          );

          this.currentProject = this.projects.find(
            x => x.id === currentProjectId
          );

          if (!this.currentProject) {
            this.currentProject = this.projects[0];
            localStorage.setItem(
              'currentProject',
              this.currentProject.id.toString()
            );
          }

          this.eventService.send({
            type: EventType.Select,
            objectType: ObjectType.Project,
            object: this.currentProject.id,
          });
        }

        this.fetchProject();
        this.updateTypes();
      });
  }

  fetchProject(isLoadStats: boolean = true): void {
    if (this.currentProject == null) {
      return;
    }

    const date = this.date.value;
    this.groups = [];
    this.expenses = [];

    if (isLoadStats) {
      this.isLoading = true;
      this.reportService
        .get(false, null, date.year(), date.month() + 1, this.currentProject.id)
        .pipe(
          finalize(() => (this.isLoading = false)),
          takeUntil(this.destroy$)
        )
        .subscribe(data => {
          this.expensesSum = _.sumBy(data, 'expensesSum');
          this.currentPeriodBudget = _.sumBy(data, 'currentPeriodBudget');
        });
    }

    this.loadExpenses(date);
  }

  changeProject(project: ProjectModel): void {
    this.currentProject = project;
    this.fetchProject();
    localStorage.setItem('currentProject', this.currentProject.id.toString());
    this.eventService.send({
      type: EventType.Select,
      objectType: ObjectType.Project,
      object: this.currentProject.id,
    });
  }

  editItem(item: any): void {
    item.projectId = this.currentProject.id;
    const dialogRef: MatDialogRef<ExpenseWidgetComponent, any> =
      this.dialog.open(ExpenseWidgetComponent, {
        data: {
          categoryType: CategoryType.Expense,
          item: { ...item },
        },
        panelClass: 'main-widget-panel',
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.fetchProject();
        }
      });
  }

  deleteItem(item: any): void {
    this.dialog
      .open(DeleteDialogComponent, {
        panelClass: 'main-widget-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.value) {
          const date = this.date.value;
          this.expenseService
            .delete(item.id, date.year(), date.month() + 1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              this.fetchProject();
            });
        }
      });
  }

  updateTypes(): void {
    this.settingsService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        data.forEach(value => {
          const type = this.types.find(x => x.enumValue == value.type);
          if (type) {
            type.value = value.name;
          }
        });
      });

    this.allTypes = _.orderBy(
      [
        ...this.types,
        {
          id: 'Payments',
          value: 'תשלומים',
          enumValue: ExpensesType.Payments,
          isEditable: false,
          order: 10,
        },
        {
          id: 'DirectDebit',
          value: 'חיוב קבוע',
          enumValue: ExpensesType.DirectDebit,
          isEditable: false,
          order: 11,
        },
      ],
      ['order']
    );
  }

  onClearType(event: any): void {
    event.stopPropagation();
    this.selectedTypeOfExpense = null;
    this.fetchProject(false);
  }

  download(): void {
    const date = this.date.value;

    let type = this.selectedTypeOfExpense;
    let typeOfExpense = null;

    if (type === 'Payments') {
      type = TypeOfPay.CreditCard;
      typeOfExpense = ExpensesType.Payments;
    } else if (type === 'DirectDebit') {
      type = TypeOfPay.CreditCard;
      typeOfExpense = ExpensesType.DirectDebit;
    }
    this.expenseService
      .export(
        null,
        null,
        date.year(),
        date.month() + 1,
        this.currentProject.id,
        type,
        typeOfExpense
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        window.open(data.url, '_blank');
      });
  }
}
