import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { NavigationEnd, Router } from '@angular/router';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  AuthenticationService,
  ReportService,
  EventService,
  ObjectType,
  EventType,
  HelperService,
  MainLayoutService,
  ScrollEventType,
  UserService,
} from '../../_services';
import { Subject } from 'rxjs';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';
import { filter, takeUntil } from 'rxjs/operators';
import { CategoryType, UserModel } from '@app/_common/models';
import { CategoryEditComponent } from '@app/_shared/category-edit/category-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { SavingWidgetComponent } from '@app/savings/saving-widget/saving-widget.component';
import { LoanWidgetComponent } from '@app/loans/loan-widget/loan-widget.component';
import { ExpenseWidgetComponent } from '@app/_shared/expense-widget/expense-widget.component';
import { SettingsComponent } from '@app/settings/settings.component';

const moment = _rollupMoment || _moment;

export enum SidebarType {
  Expenses,
  Savings,
  Loans,
}

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  private date: Moment;
  private currentProjectId: number;

  SidebarType = SidebarType;
  sidebarType: SidebarType = SidebarType.Expenses;

  currentUser: UserModel;

  @ViewChild('mainWrapper', { static: true }) mainWrapper: ElementRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private eventService: EventService,
    private reportService: ReportService,
    private helperService: HelperService,
    private mainLayoutService: MainLayoutService,
    private userService: UserService,
    public dialog: MatDialog
  ) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((data: NavigationEnd) => {
        if (data.url.indexOf('/savings') > -1) {
          this.sidebarType = SidebarType.Savings;
        } else if (data.url.indexOf('/loans') > -1) {
          this.sidebarType = SidebarType.Loans;
        } else {
          this.sidebarType = SidebarType.Expenses;
        }
      });
  }

  ngOnInit() {
    this.loadCurrentUser();
    this.date = this.helperService.getMonthStartDate();
    this.initEvents();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initEvents(): void {
    this.eventService
      .getUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (
          event.objectType === ObjectType.Project &&
          event.type === EventType.Select
        ) {
          this.currentProjectId = event.object;
        }
        if (
          event.objectType === ObjectType.Date &&
          event.type === EventType.Select
        ) {
          this.date = event.object;
        }
      });

    this.mainLayoutService
      .getUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.eventType === ScrollEventType.ToTop) {
          this.mainWrapper.nativeElement.scrollTop = 0;
        }
      });
  }

  downloadReport() {
    let year: number, month: number;
    if (this.date) {
      year = this.date.year();
      month = this.date.month() + 1;
    }

    this.reportService
      .generateMonthReport(null, year, month, this.currentProjectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        window.open(data.url, '_blank');
      });
  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  loadCurrentUser(): void {
    this.userService
      .getMe()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.currentUser = data;
      });
  }

  createCategory(): void {
    this.isMenuOpen = false;
    this.dialog.open(CategoryEditComponent, {
      data: {},
      panelClass: 'main-widget-panel',
    });
  }

  createSaving(): void {
    this.isMenuOpen = false;
    this.dialog.open(SavingWidgetComponent, {
      data: null,
      panelClass: 'main-widget-panel',
    });
  }

  createLoan(): void {
    this.isMenuOpen = false;
    this.dialog.open(LoanWidgetComponent, {
      data: null,
      panelClass: 'main-widget-panel',
    });
  }

  createExpense(): void {
    this.isMenuOpen = false;
    this.dialog.open(ExpenseWidgetComponent, {
      data: {
        categoryType: CategoryType.Expense,
      },
      panelClass: 'main-widget-panel',
    });
  }

  createIncome(): void {
    this.isMenuOpen = false;
    this.dialog.open(ExpenseWidgetComponent, {
      data: {
        categoryType: CategoryType.Income,
      },
      panelClass: 'main-widget-panel',
    });
  }

  isMenuOpen: boolean = false;
  changeMenuState(state: boolean): void {
    if (this.isMenuOpen) {
      setTimeout(() => {
        this.isMenuOpen = state;
      }, 200);
    } else {
      this.isMenuOpen = state;
    }
  }

  openSettings(): void {
    this.dialog.open(SettingsComponent, {
      data: {},
      panelClass: 'main-widget-panel',
    });
  }
}
