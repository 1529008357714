import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';
import {
  AuthenticationService,
  EventService,
  EventType,
  HelperService,
  ObjectType,
  PaymentService,
  SavingService,
} from '@app/_services';
import moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import {
  PaymentModel,
  SavingModel,
  SavingPaymentType,
} from '@app/_common/models/saving';
import { PaymentWidgetComponent } from '../payment-widget/payment-widget.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SavingWidgetComponent } from '../saving-widget/saving-widget.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '@app/_shared/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-saving',
  templateUrl: './saving.component.html',
  styleUrls: ['./saving.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class SavingComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public date: UntypedFormControl;
  public selectedMonth: string = '';

  public groups: any = [];
  private payments: PaymentModel[] = [];

  public isLoading: boolean = false;
  public isLoadingTable: boolean = false;

  public savingId: number;
  public saving: SavingModel;

  public total: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private savingService: SavingService,
    private eventService: EventService,
    private authenticationService: AuthenticationService,
    private helperService: HelperService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.savingId = +params['id'];
      this.eventService.send({
        type: EventType.Select,
        objectType: ObjectType.Saving,
        object: { savingId: this.savingId },
      });
    });

    this.eventService
      .getUpdate()
      .pipe(
        filter(e => e.objectType === ObjectType.SavingPayment),
        takeUntil(this.destroy$)
      )
      .subscribe(event => {
        if (
          ((event.type === EventType.Create ||
            event.type === EventType.Update) &&
            event.object.savingId === this,
          this.savingId)
        ) {
          this.load();
        }
      });

    this.setStartDate();
    this.updateDateRange();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setStartDate(): void {
    const selectedDate = localStorage.getItem('selectedDate');

    let monthDate;
    if (selectedDate) {
      const obj = JSON.parse(selectedDate);
      if (moment.utc() < moment(obj.expired)) {
        monthDate = moment(obj.date, null, 'he');
      }
    }

    if (!monthDate || !monthDate.isValid()) {
      const date = this.helperService.getMonthStartDate();
      monthDate = moment(
        new Date(
          date.year(),
          date.month(),
          this.authenticationService.currentUserValue.user.startMonthDay
        ),
        null,
        'he'
      );
    }
    this.date = new UntypedFormControl(monthDate);
  }

  updateDateRange(): void {
    const monthDate: Moment = moment(
      new Date(
        this.date.value.year(),
        this.date.value.month(),
        this.authenticationService.currentUserValue.user.startMonthDay
      ),
      null,
      'he'
    );
    this.selectedMonth = monthDate.format('YYYY MMM');

    this.load();
  }

  load(): void {
    const date = this.date.value;
    this.groups = [];
    this.payments = [];

    this.isLoading = true;
    this.savingService
      .list(this.savingId)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        if (data && data.length) {
          this.saving = data[0];
        }
      });

    this.loadPayments(date);
  }

  loadPayments(date: any): void {
    this.isLoadingTable = true;

    this.paymentService
      .list(0, null, this.savingId, date.year(), date.month() + 1)
      .pipe(
        finalize(() => (this.isLoadingTable = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.payments = data;

        const groups = _.groupBy(this.payments, x => {
          //console.info(x.dateTime);
          const date = moment.utc(x.dateTime, null, 'he').local();
          return date.format('dddd, D MMMM YYYY');
        });

        const result = [];
        _.forEach(groups, function (value, key) {
          result.push({
            date: key,
            payments: _.sortBy(value, x => moment.utc(x.dateTime)),
          });
        });

        this.groups = result;
        this.total =
          _.sumBy(
            this.payments.filter(x => x.type === SavingPaymentType.Deposit),
            'amount'
          ) -
          _.sumBy(
            this.payments.filter(x => x.type === SavingPaymentType.Withdrawal),
            'amount'
          );
      });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.date.setValue(normalizedMonth);
    datepicker.close();

    localStorage.setItem(
      'selectedDate',
      JSON.stringify({
        date: this.date.value,
        expired: moment.utc().add(1, 'day'),
      })
    );
    this.updateDateRange();
  }

  editItem(item: any): void {
    const dialogRef: MatDialogRef<PaymentWidgetComponent, any> =
      this.dialog.open(PaymentWidgetComponent, {
        data: {
          ...item,
        },
        panelClass: 'main-widget-panel',
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.eventService.send({
            type: EventType.Update,
            objectType: ObjectType.SavingPayment,
            object: result,
          });
          this.load();
        }
      });
  }

  deleteItem(item: any): void {
    this.dialog
      .open(DeleteDialogComponent, {
        panelClass: 'main-widget-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.value) {
          const date = moment(item.dateTime);
          this.paymentService
            .delete(item.id, date.year(), date.month() + 1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              this.eventService.send({
                type: EventType.Delete,
                objectType: ObjectType.SavingPayment,
                object: item,
              });
              this.load();
            });
        }
      });
  }

  editSaving(): void {
    const dialogRef: MatDialogRef<SavingWidgetComponent, any> =
      this.dialog.open(SavingWidgetComponent, {
        data: {
          ...this.saving,
        },
        panelClass: 'main-widget-panel',
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.eventService.send({
            type: EventType.Update,
            objectType: ObjectType.Saving,
            object: { ...result },
          });
          this.load();
        }
      });
  }

  deleteSaving(): void {
    this.dialog
      .open(DeleteDialogComponent, {
        panelClass: 'main-widget-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          if (result?.value) {
            this.savingService
              .delete(this.saving.id)
              .pipe(takeUntil(this.destroy$))
              .subscribe(data => {
                this.eventService.send({
                  type: EventType.Delete,
                  objectType: ObjectType.Saving,
                  object: this.saving,
                });
                this.router.navigate(['savings']);
              });
          }
        },
        err => this.toastr.error('Error')
      );
  }
}
