import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MY_FORMATS_MONTH } from '@app/_common/dateFormats';
import { SavingModel } from '@app/_common/models/saving';
import {
  AuthenticationService,
  DataService,
  EventService,
  EventType,
  HelperService,
  ObjectType,
  SavingService,
} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { SavingWidgetComponent } from './saving-widget/saving-widget.component';
import * as _ from 'lodash';
import { DeleteDialogComponent } from '@app/_shared/delete-dialog/delete-dialog.component';
@Component({
  selector: 'app-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
  ],
})
export class SavingsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  rows: SavingModel[] = [];

  public startMonthDate: string;
  public endMonthDate: string;
  public date: UntypedFormControl;

  totalSavingAmount: number = 0;

  public isLoading: boolean = false;

  constructor(
    private savingService: SavingService,
    private authenticationService: AuthenticationService,
    private eventService: EventService,
    private dataService: DataService,
    private helperService: HelperService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setStartDate();

    this.eventService
      .getUpdate()
      .pipe(
        filter(
          e =>
            e.objectType === ObjectType.Saving ||
            e.objectType === ObjectType.SavingPayment
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(event => {
        if (
          event.type === EventType.Create ||
          event.type === EventType.Update ||
          event.type === EventType.Delete
        ) {
          this.load();
        }
      });

    this.dataService.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.date) {
        this.date.setValue(data.date);
      }

      this.updateDateRange(false);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setStartDate(): void {
    const selectedDate = localStorage.getItem('selectedDate');

    let monthDate;
    if (selectedDate) {
      const obj = JSON.parse(selectedDate);
      if (moment.utc() < moment(obj.expired)) {
        monthDate = moment(obj.date, null, 'he');
      }
    }

    if (!monthDate || !monthDate.isValid()) {
      const date = this.helperService.getMonthStartDate();
      monthDate = moment(
        new Date(
          date.year(),
          date.month(),
          this.authenticationService.currentUserValue.user.startMonthDay
        ),
        null,
        'he'
      );
    }

    this.date = new UntypedFormControl(monthDate);
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.date.setValue(normalizedMonth);
    datepicker.close();

    localStorage.setItem(
      'selectedDate',
      JSON.stringify({
        date: this.date.value,
        expired: moment.utc().add(1, 'day'),
      })
    );
    this.updateDateRange();
  }

  updateDateRange(updateData: boolean = true): void {
    if (updateData) {
      this.dataService.updatedDataSelection({ date: this.date.value });
    }

    const monthDate = moment(
      new Date(
        this.date.value.year(),
        this.date.value.month(),
        this.authenticationService.currentUserValue.user.startMonthDay
      )
    );
    this.startMonthDate = monthDate.format('DD.MM.YYYY');
    this.endMonthDate = monthDate
      .add(1, 'months')
      .add(-1, 'days')
      .format('DD.MM.YYYY');

    this.load();
    this.eventService.send({
      type: EventType.Select,
      objectType: ObjectType.Date,
      object: this.date.value,
    });
  }

  load(): void {
    this.isLoading = true;
    this.savingService
      .list()
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.rows = data;
        this.totalSavingAmount =
          _.sumBy(data, 'totalDepositsAmount') -
          _.sumBy(data, 'totalWithdrawalAmount');
      });
  }

  editItem(event: MouseEvent, item: any): void {
    event.stopPropagation();
    event.preventDefault();

    const dialogRef: MatDialogRef<SavingWidgetComponent, any> =
      this.dialog.open(SavingWidgetComponent, {
        data: {
          ...item,
        },
        panelClass: 'main-widget-panel',
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.eventService.send({
            type: EventType.Update,
            objectType: ObjectType.Saving,
            object: { ...result },
          });
        }
      });
  }

  deleteItem(event: MouseEvent, item: any): void {
    event.stopPropagation();
    event.preventDefault();

    this.dialog
      .open(DeleteDialogComponent, {
        panelClass: 'main-widget-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.value) {
          this.savingService
            .delete(item.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              this.eventService.send({
                type: EventType.Delete,
                objectType: ObjectType.Saving,
                object: item,
              });
            });
        }
      });
  }
}
