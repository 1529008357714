import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CategoryModel, ProjectModel, ProjectType } from '@app/_common/models';
import { Subject } from 'rxjs';
import {
  ReportService,
  ProjectService,
  CategoryService,
  EventService,
  ObjectType,
  EventType,
} from '@app/_services';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import * as Chart from 'chart.js';
import 'chartjs-plugin-style';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit, OnDestroy {
  public projectControl = new UntypedFormControl();
  projects: ProjectModel[] = [];
  currentProject: ProjectModel;

  public categoryControl = new UntypedFormControl();
  categories: CategoryModel[] = [];
  selectedCategoryIds: number[] = [];

  lineChart: any = {};
  data = {
    labels: [],
    datasets: [
      {
        label: 'expense',
        borderColor: '#cba469',
        pointBorderColor: '#87b3bf',
        pointBackgroundColor: '#87b3bf',
        pointRadius: 7,
        pointHoverRadius: 7,
        pointHitRadius: 7,
        pointBorderWidth: 1,
        borderWidth: 1,
        backgroundColor: 'transparent',
        lineTension: 0.3,
        categoryPercentage: 0.2,
        data: [],
      },
    ],
  };

  public isLoading: boolean = false;

  average: number = 0;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private projectService: ProjectService,
    private categoryService: CategoryService,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.createChart();
    this.fetchProjects();

    this.eventService
      .getUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (
          event.objectType === ObjectType.Expense &&
          event.type === EventType.Create
        ) {
          this.loadChartData(this.selectedCategoryIds);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchProjects(): void {
    this.projectService
      .list(null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.projects = data.filter(x => x.type != ProjectType.AdHoc); // don't support AdHoc on phase 1
        if (this.projects.length > 0) {
          const currentProjectId: number = +(
            localStorage.getItem('currentProject') || '0'
          );

          this.currentProject = this.projects.find(
            x => x.id === currentProjectId
          );

          if (!this.currentProject) {
            this.currentProject = this.projects[0];
            localStorage.setItem(
              'currentProject',
              this.currentProject.id.toString()
            );
          }

          this.projectControl.setValue(this.currentProject.id);
          this.load();
        }
      });
  }

  updateProject(event: any): void {
    this.currentProject = this.projects.find(x => x.id === event.value);
    this.load();
  }

  load(): void {
    this.isLoading = true;

    this.categoryService
      .statsExpensesList(this.currentProject.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.categories = _.sortBy(data, [x => x.name.toLowerCase()]);
        this.selectedCategoryIds = this.categories.map(x => x.id);

        this.updateSelectAllCategoriesState();
        this.loadChartData(this.selectedCategoryIds);
      });
  }

  updateCategoriesHandler(event): void {
    this.selectedCategoryIds = event.value;
    this.updateSelectAllCategoriesState();
  }

  selectAllCategories(event): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.selectedCategoryIds.indexOf(0) > -1) {
      this.selectedCategoryIds = [];
    } else {
      this.selectedCategoryIds = [...this.categories.map(x => x.id).sort(), 0];
    }
  }

  updateSelectAllCategoriesState(): void {
    const selectedCategoryIds = this.selectedCategoryIds
      .filter(x => x !== 0)
      .sort();
    const allCategories = this.categories.map(x => x.id).sort();
    if (_.isEqual(selectedCategoryIds, allCategories)) {
      this.selectedCategoryIds = [...selectedCategoryIds, 0];
    } else {
      this.selectedCategoryIds = selectedCategoryIds;
    }
  }

  updateCategoriesState(isOpen): void {
    if (!isOpen) {
      this.loadChartData(this.selectedCategoryIds);
    }
  }

  loadChartData(selectedCategoryIds): void {
    this.isLoading = true;
    this.reportService
      .getCategoriesYearlyReport(selectedCategoryIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.data.labels = data.months.map(x =>
          moment(x.date).locale('he').format('MMM')
        );
        this.data.datasets[0].data = data.months.map(x => x.amount);
        this.lineChart.update();
        this.average = _.mean(data.months.map(x => x.amount)).toFixed(0);
        this.isLoading = false;
      });
  }

  createChart(): void {
    this.lineChart = new Chart('statsCanvas', {
      type: 'line',
      data: this.data,
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                color: '#ddccc2',
                lineWidth: 2,
                offsetGridLines: true,
                zeroLineBorderDashOffset: 100,
              },
              ticks: {
                fontFamily: "'Open Sans', 'sans-serif'",
                fontSize: 15,
                fontColor: '#8b8989',
                min: 0,
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false,
                color: '#ddccc2',
                lineWidth: 2,
              },
              ticks: {
                min: 0,
                padding: 20,
                fontFamily: "'Open Sans', 'sans-serif'",
                fontSize: 15,
                fontColor: '#8b8989',
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            color: '#8b8989',
            font: {
              family: "'Open Sans', 'sans-serif'",
              size: 15,
            },
            anchor: 'end',
            align: -80,
            formatter: (value: number, context) => {
              return value.toLocaleString('en-us');
            },
          },
        },
        layout: {
          padding: {
            left: 0,
            right: 50,
            top: 50,
            bottom: 0,
          },
        },
      },
    });
  }
}
