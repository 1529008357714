import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeOfCategoryCycle'
})
export class TypeOfCategoryCyclePipe implements PipeTransform {
  transform(value: number): any {
    switch (value) {
      case 0:
      case 2:
      case 3:
        return 'חודשי';
      case 1:
        return 'שבועי';
      case 4:
      case 5:
        return 'חד פעמי';
      default:
        return '';
    }
  }
}
